/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import VisitService from "../../services/VisitService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import TokenService from "../../services/token.service";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import util from "../../services/util";
import _ from "lodash";
import FUSTabs from "../../FUSComponents/FUSTabs/FUSTabs";
import { Color } from "highcharts";

const ManageVisit = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    data: null,
    type: null,
  });

  const [activeTab, setActiveTab] = useState("PARTY");
  const token = TokenService.getUser();
  const agency_id = token?.agent?.agent_user_id;
  const [AllParty, setAllParty] = useState([]);
  const [AllLeads, setAllLeads] = useState([]);

  const getPartyNamebyAgencyId = async (second) => {
    try {
      const result = await VisitService?.get_party_by_agency_id(agency_id);
      if (result?.data?.errorCode === 0) {
        setAllParty(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getAllLeadsByAgencyId = async (second) => {
    try {
      const result = await VisitService?.getAllLeadsByAgencyId();
      if (result?.data?.errorCode === 0) {
        setAllLeads(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const columns = [
    {
      dataField: "party_name",
      text: "Party Name",
      formatter: (cellContent, row) => {
        return (
          <>
            <a
              href="#"
              className="text-decoration-none"
              onClick={() => {
                setShowModal({
                  state: true,
                  data: row,
                  type: "VISIT_ACTIVITY",
                });
              }}
            >
              {row?.party_name}
            </a>
          </>
        );
      },
      editable: false,
    },
    {
      dataField: "party_code",
      text: activeTab === "PARTY" ? "Party Code" : null,
      editable: false,
    },
    {
      dataField: "primary_mobile_no",
      text: "Mobile No.",
      editable: false,
    },
    {
      dataField: "pancard",
      text: "Pan Card",
      editable: false,
    },
    {
      dataField: "gst_no",
      text: "GSTIN No.",
      editable: false,
    },
    {
      dataField: "aadhar_no",
      text: "Aadhar No.",
      editable: false,
    },
    {
      dataField: "address",
      text: "Address",
      editable: false,
    },
    {
      dataField: "postal_code",
      text: "Postal Code",
      editable: false,
    },
    {
      dataField: "total_expense",
      text: "Total Expense",
      editable: false,
      formatter: (cellContent, row) => {
        // total_expense from row
        const totalExpense = row?.visits
          ? row?.visits
              .map((visit) => visit?.total_expense || 0) // Map through visits to get total_expense
              .reduce((total, expense) => total + expense, 0) // Sum of total expenses
          : 0; // If no visits found return 0

        return (
          <a
            href="#"
            className="text-decoration-none"
            onClick={() => {
              setShowModal({
                state: true,
                data: row,
                type: "EXPENSE_DETAILS", // Call expense modal
              });
            }}
          >
            Rs. {totalExpense}
          </a>
        );
      },
    },
  ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: activeTab === "PARTY" ? AllParty.length : AllLeads.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  useEffect(() => {
    if (activeTab === "LEADS") {
      getAllLeadsByAgencyId();
    }
    getPartyNamebyAgencyId();
  }, [activeTab]);

  const tabOptions = [
    {
      label: "Party",
      value: "PARTY",
    },
    {
      label: "Leads",
      value: "LEADS",
    },
  ];
  return (
    <>
      <LayoutContainer
        title1={"Visit Management"}
        title2={"Visit Management"}
        title3={"Visit"}
      >
        <div className="m-3">
          <FUSTabs
            tabOptions={tabOptions}
            activeTab={activeTab}
            setTab={(val) => setActiveTab(val)}
          />
          <div>
            {activeTab === "PARTY" && (
              <div>
                <BootstrapTable
                  classes="border border-1"
                  keyField="id"
                  data={AllParty}
                  columns={columns}
                  sizePerPage={10}
                  filter={filterFactory()}
                  pagination={paginationFactory(options)}
                />
              </div>
            )}
            {activeTab === "LEADS" && (
              <div>
                <BootstrapTable
                  classes="border border-1"
                  keyField="id"
                  data={AllLeads}
                  columns={columns}
                  sizePerPage={10}
                  filter={filterFactory()}
                  pagination={paginationFactory(options)}
                />
              </div>
            )}
          </div>
        </div>

        {showModal?.state &&
          !_.isNil(showModal?.data) &&
          showModal?.type === "VISIT_ACTIVITY" && (
            <FUSModal
              title="Activity History"
              showModal={showModal.state}
              size={"lg"}
              handleClose={() =>
                setShowModal((prev) => ({ ...prev, state: false }))
              }
              centered
            >
              {showModal?.data?.visits?.length > 0 ? (
                showModal?.data?.visits?.map((act) => (
                  <>
                    <h6 style={{ backgroundColor: "var(--themeBlue-color)" }}>
                      {act?.visit_date + " ( " + act?.visit_duration + ")"}
                    </h6>
                    <div className="d-flex">
                      <p className="fw-bold">Start Time:</p>
                      <p className="ps-2">
                        {util?.formatTo12HourTime(act?.starts_at)}
                      </p>
                    </div>
                    <div className="d-flex">
                      <p className="fw-bold">End Time:</p>
                      <p className="ps-2">
                        {util?.formatTo12HourTime(act?.ends_at)}
                      </p>
                    </div>

                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Activity Name</th>
                          <th>Activity Remark</th>
                          <th>Activity Type</th>
                          <th>Next Step</th>
                          <th>Outcomes</th>
                          <th>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {act?.activities?.map((data) => (
                          <tr key={data?.id}>
                            <td>{data?.activity_name}</td>
                            <td>{data?.activity_remark}</td>
                            <td>{data?.activity_type}</td>
                            <td>{data?.next_step}</td>
                            <td>{data?.outcomes}</td>
                            <td>{data?.notes}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ))
              ) : (
                <p>No Data Found</p>
              )}
            </FUSModal>
          )}

        {showModal?.state &&
          !_.isNil(showModal?.data) &&
          showModal?.type === "EXPENSE_DETAILS" && (
            <FUSModal
              title="Expense Details"
              showModal={showModal.state}
              size={"lg"}
              handleClose={() =>
                setShowModal((prev) => ({ ...prev, state: false }))
              }
              centered
            >
              {showModal?.data?.visits?.some(
                (visit) => visit?.total_expense > 0
              ) ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Expense Date</th>
                      <th>Expense Name</th>
                      <th>Expense Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showModal?.data?.visits?.map((act, visitIndex) =>
                      act?.expenses?.map((data, index) => (
                        <tr key={`${visitIndex}-${index}`}>
                          <td>{data?.expense_date}</td>{" "}
                          <td>{data?.expense_name}</td>
                          <td>{data?.expense_amt}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              ) : (
                <p>No data found for this record.</p> // Show message if there are no visits or expenses
              )}
            </FUSModal>
          )}
      </LayoutContainer>
    </>
  );
};

export default ManageVisit;

// StoreProvider.js
import React, { createContext, useState } from "react";
import { useEffect } from "react";
import TokenService from "../services/token.service";
import _ from "lodash";
export const StoreContext = createContext();
function StoreProvider({ children }) {
  const [openSideNavInDevices, setOpenSideNavInDevices] = useState(false);
  const [rolePermission, setRolePermission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const role = TokenService.getRolePermission();
    setRolePermission(role);
  }, []);

  const isFeatureEnabled = (feature) => {
    let obj = rolePermission?.filter(
      (item) =>
        item.feature.toUpperCase().trim() === feature.toUpperCase().trim()
    );
    return obj?.length > 0 ? true : false;
  };

  const isFunctionalityEnabled = (feature, functionality) => {
    let obj = rolePermission?.filter(
      (item) =>
        item.feature.toUpperCase().trim() === feature.toUpperCase().trim()
    );
    return _.isEmpty(obj)
      ? false
      : obj[0]?.functionality?.includes(functionality?.toUpperCase().trim());
  };

  return (
    <StoreContext.Provider
      value={{
        openSideNavInDevices,
        setOpenSideNavInDevices,
        rolePermission,
        setRolePermission,
        isFeatureEnabled,
        isFunctionalityEnabled,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

export default StoreProvider;

import React from "react";
import * as FaIcons from "react-icons/fa6";
import * as RiIcons from "react-icons/ri";
import * as TbIcons from "react-icons/tb";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as LuIcons from "react-icons/lu";
import * as LiaIcons from "react-icons/lia";
import * as FiIcons from "react-icons/fi";
import * as CiIcons from "react-icons/ci";
import * as BiIcons from "react-icons/bi";
import * as FaIcon from "react-icons/fa";
import * as Io5Icons from "react-icons/io5";
import * as SiIcons from "react-icons/si";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RxIcons from "react-icons/rx";

const FUSIcon = ({ iconSrc, iconName, ...rest }) => {
  let IconComponent;

  switch (iconSrc) {
    case "fa6":
      IconComponent = FaIcons[iconName];
      break;
    case "ri":
      IconComponent = RiIcons[iconName];
      break;
    case "tb":
      IconComponent = TbIcons[iconName];
      break;
    case "md":
      IconComponent = MdIcons[iconName];
      break;
    case "lu":
      IconComponent = LuIcons[iconName];
      break;
    case "lia":
      IconComponent = LiaIcons[iconName];
      break;
    case "fi":
      IconComponent = FiIcons[iconName];
      break;
      case "rx":
      IconComponent = RxIcons[iconName];
      break;
    case "ci":
      IconComponent = CiIcons[iconName];
      break;
    case "bi":
      IconComponent = BiIcons[iconName];
      break;
    case "fa":
      IconComponent = FaIcon[iconName];
      break;
    case "io5":
      IconComponent = Io5Icons[iconName];
      break;
    case "si":
      IconComponent = SiIcons[iconName];
      break;
    case "ai":
      IconComponent = AiIcons[iconName];
      break;
    case "io":
      IconComponent = IoIcons[iconName];
      break;
    case "gr":
      IconComponent = GrIcons[iconName];
      break;

    default:
      return null;
  }

  return IconComponent ? <IconComponent {...rest} /> : null;
};

export default FUSIcon;

import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";

const TitleConfiguration = () => {
  const [brandingData, setBrandingData] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
    type: null,
  });
  const [formData, setFormData] = useState({
    id: "",
    app_name: "",
    app_ver_android: "",
    app_ver_ios: "",
    app_ver_b_android: "",
    app_ver_b_ios: "",
    app_title: "",
    app_title_bus: "",
    description: "",
    description_bus: "",
  });
  const [errors, setErrors] = useState({});

  const columns = [
    {
      dataField: "app_name",
      text: "App Name",
      sort: true,
      editable: false,
      filter: textFilter(),
      formatter: (cellContent, row) => {
        const textColor = row?.is_active ? "text-success" : "text-danger";
        return (
          <span
            className={textColor}
            style={{ cursor: "pointer" }}
            onClick={() => handleStatusToggle(row)}
          >
            {cellContent}
          </span>
        );
      },
    },
    {
      dataField: "app_ver_android",
      text: "Android Version",
    },
    {
      dataField: "app_ver_ios",
      text: "iOS Version",
    },
    {
      dataField: "app_ver_b_android",
      text: "Android Version (B2B)",
    },
    {
      dataField: "app_ver_b_ios",
      text: "iOS Version (B2B)",
    },
    {
      dataField: "app_title",
      text: "App Title",
      sort: true,
      editable: false,
      filter: textFilter(),
      formatter: (cellContent, row) => (
        <span title={row?.description}>{cellContent}</span>
      ),
    },
    {
      dataField: "app_title_bus",
      text: "Business App Title",
      formatter: (cellContent, row) => (
        <span title={row?.description_bus}>{cellContent}</span>
      ),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "7",
        value: 7,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: brandingData.length,
      },
    ],
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const getAppTitleInfo = async () => {
    try {
      const result = await MstService?.getAppTitleInfo();
      if (result?.data?.errorCode === 0) {
        setBrandingData(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getAppTitleInfo();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData?.app_name.trim()) {
      newErrors.app_name = "App name is required";
    }
    if (!formData?.app_title_bus.trim()) {
      newErrors.app_title_bus = "Business app title is required";
    } else if (!/^[A-Za-z0-9\s]+$/.test(formData.app_title_bus.trim())) {
      newErrors.app_title_bus = "No special characters allowed.";
    }
    if (
      formData?.app_ver_android &&
      !/^[0-9]((\.)[0-9]){0,2}$/.test(formData.app_ver_android)
    ) {
      newErrors.app_ver_android = "Invalid version format (e.g., 1.0.0)";
    }
    if (
      formData?.app_ver_ios &&
      !/^[0-9]((\.)[0-9]){0,2}$/.test(formData.app_ver_ios)
    ) {
      newErrors.app_ver_ios = "Invalid version format (e.g., 1.0.0)";
    }
    if (!formData?.app_ver_b_android.trim()) {
      newErrors.app_ver_b_android = "Android Version is required.";
    } else if (!/^[0-9]((\.)[0-9]){0,3}$/.test(formData.app_ver_b_android)) {
      newErrors.app_ver_b_android = "Invalid version format (e.g., 1.0.0)";
    }
    if (!formData?.app_ver_b_ios.trim()) {
      newErrors.app_ver_b_ios = "iOS Version is required.";
    } else if (!/^[0-9]((\.)[0-9]){0,3}$/.test(formData.app_ver_b_ios)) {
      newErrors.app_ver_b_ios = "Invalid version format (e.g., 1.0.0)";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const result = await MstService.addAppTitleInfo(formData);

        if (result?.data?.errorCode === 0) {
          getAppTitleInfo();
          handleCloseModal();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          handleCloseModal();
          const errorToast = toast.error(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleDelete = async (row) => {
    const isDeleteConfirm = window.confirm("Do you really want to delete?");
    if (isDeleteConfirm) {
      try {
        const result = await MstService.deleteBrandingTitle({
          id: row.id,
        });
        if (result?.data?.errorCode === 0) {
          getAppTitleInfo();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleEditClick = (row) => {
    setShowModal((prev) => ({
      ...prev,
      state: true,
      type: "EDIT",
    }));
    setFormData({
      id: row.id,
      app_name: row.app_name,
      app_ver_android: row.app_ver_android,
      app_ver_ios: row.app_ver_ios,
      app_ver_b_android: row.app_ver_b_android,
      app_ver_b_ios: row.app_ver_b_ios,
      app_title: row.app_title,
      app_title_bus: row.app_title_bus,
      description: row.description,
      description_bus: row.description_bus,
    });
  };

  const handleEdit = async () => {
    if (validateForm()) {
      try {
        const result = await MstService.updateAppTitleInfo(formData);
        if (result?.data?.errorCode === 0) {
          getAppTitleInfo();
          handleCloseModal();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleStatusToggle = async (row) => {
    const newStatus = !row.is_active;
    const updatedData = { id: row.id, is_active: newStatus };
    try {
      const result = await MstService.updateBrandingStatus(updatedData);
      if (result?.data?.errorCode === 0) {
        const updatedBrandingData = brandingData.map((item) =>
          item.id === row.id ? { ...item, is_active: newStatus } : item
        );
        setBrandingData(updatedBrandingData);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleCloseModal = () => {
    setShowModal((prev) => ({ ...prev, state: false }));
    setFormData({
      id: "",
      app_name: "",
      app_ver_android: "",
      app_ver_ios: "",
      app_ver_b_android: "",
      app_ver_b_ios: "",
      app_title: "",
      app_title_bus: "",
      description: "",
      description_bus: "",
    });
    setErrors({});
  };

  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={"Title Configuration"}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Create"}
              onClick={() => {
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "ADD",
                }));
              }}
            />
          </>
        }
      >
        <div className="px-3 py-3">
          <div className="col-md-12">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={brandingData}
              columns={columns}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
            />
          </div>
        </div>

        {showModal.state && (
          <FUSModal
            title={showModal.type === "ADD" ? "Add Details" : "Edit Details"}
            showModal={showModal.state}
            size={"md"}
            handleClose={() => handleCloseModal()}
            centered
          >
            {showModal.type === "ADD" && (
              <div className="px-3 col py-3">
                <div className="row">
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>App Name</label>
                    <input
                      type="text"
                      name="app_name"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter app name"
                      value={formData.app_name}
                      onChange={handleChange}
                    />
                    {errors.app_name && (
                      <div className="text-danger">{errors.app_name}</div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>Android Version</label>
                    <input
                      type="text"
                      name="app_ver_android"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Android version"
                      value={formData.app_ver_android}
                      onChange={handleChange}
                    />
                    {errors.app_ver_android && (
                      <div className="text-danger">
                        {errors.app_ver_android}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>iOS Version</label>
                    <input
                      type="text"
                      name="app_ver_ios"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter iOS version"
                      value={formData.app_ver_ios}
                      onChange={handleChange}
                    />
                    {errors.app_ver_ios && (
                      <div className="text-danger">{errors.app_ver_ios}</div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>Android Version (B2B)</label>
                    <input
                      type="text"
                      name="app_ver_b_android"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter Android version for B2B"
                      value={formData.app_ver_b_android}
                      onChange={handleChange}
                    />
                    {errors.app_ver_b_android && (
                      <div className="text-danger">
                        {errors.app_ver_b_android}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>iOS Version (B2B)</label>
                    <input
                      type="text"
                      name="app_ver_b_ios"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter iOS version for B2B"
                      value={formData.app_ver_b_ios}
                      onChange={handleChange}
                    />
                    {errors.app_ver_b_ios && (
                      <div className="text-danger">{errors.app_ver_b_ios}</div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>App Title</label>
                    <input
                      type="text"
                      name="app_title"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter app title"
                      value={formData.app_title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>Business App Title</label>
                    <input
                      type="text"
                      name="app_title_bus"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter business app title"
                      value={formData.app_title_bus}
                      onChange={handleChange}
                    />
                    {errors.app_title_bus && (
                      <div className="text-danger">{errors.app_title_bus}</div>
                    )}
                  </div>
                  <div className="col-md-12 ps-0 ms-0 mb-3">
                    <label>Description</label>
                    <textarea
                      name="description"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter description"
                      rows="2"
                      value={formData.description}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12 ps-0 ms-0 mb-4">
                    <label>Business Description</label>
                    <textarea
                      name="description_bus"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter business description"
                      rows="2"
                      value={formData.description_bus}
                      onChange={handleChange}
                    />
                  </div>
                  <FUSButton
                    labelText="Add"
                    buttonType="primary"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}

            {showModal.type === "EDIT" && (
              <div className="px-3 col py-3">
                <div className="row">
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>App Name</label>
                    <input
                      type="text"
                      name="app_name"
                      className="form-control form-control-sm mt-1"
                      value={formData.app_name}
                      placeholder="Enter app name"
                      onChange={handleChange}
                    />
                    {errors.app_name && (
                      <div className="text-danger">{errors.app_name}</div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>Android Version</label>
                    <input
                      type="text"
                      name="app_ver_android"
                      className="form-control form-control-sm mt-1"
                      value={formData.app_ver_android}
                      placeholder="Enter Android version"
                      onChange={handleChange}
                    />
                    {errors.app_ver_android && (
                      <div className="text-danger">
                        {errors.app_ver_android}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>iOS Version</label>
                    <input
                      type="text"
                      name="app_ver_ios"
                      className="form-control form-control-sm mt-1"
                      value={formData.app_ver_ios}
                      placeholder="Enter iOS version"
                      onChange={handleChange}
                    />
                    {errors.app_ver_ios && (
                      <div className="text-danger">{errors.app_ver_ios}</div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>Android Version (B2B)</label>
                    <input
                      type="text"
                      name="app_ver_b_android"
                      className="form-control form-control-sm mt-1"
                      value={formData.app_ver_b_android}
                      placeholder="Enter Android version for B2B"
                      onChange={handleChange}
                    />
                    {errors.app_ver_b_android && (
                      <div className="text-danger">
                        {errors.app_ver_b_android}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>iOS Version (B2B)</label>
                    <input
                      type="text"
                      name="app_ver_b_ios"
                      className="form-control form-control-sm mt-1"
                      value={formData.app_ver_b_ios}
                      placeholder="Enter iOS version for B2B"
                      onChange={handleChange}
                    />
                    {errors.app_ver_b_ios && (
                      <div className="text-danger">{errors.app_ver_b_ios}</div>
                    )}
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>App Title</label>
                    <input
                      type="text"
                      name="app_title"
                      className="form-control form-control-sm mt-1"
                      value={formData.app_title}
                      placeholder="Enter app title"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 ps-0 ms-0 mb-3">
                    <label>Business App Title</label>
                    <input
                      type="text"
                      name="app_title_bus"
                      className="form-control form-control-sm mt-1"
                      value={formData.app_title_bus}
                      placeholder="Enter business app title"
                      onChange={handleChange}
                    />
                    {errors.app_title_bus && (
                      <div className="text-danger">{errors.app_title_bus}</div>
                    )}
                  </div>
                  <div className="col-md-12 ps-0 ms-0 mb-3">
                    <label>Description</label>
                    <textarea
                      name="description"
                      className="form-control form-control-sm mt-1"
                      value={formData.description}
                      placeholder="Enter description"
                      rows="2"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12 ps-0 ms-0 mb-4">
                    <label>Business Description</label>
                    <textarea
                      name="description_bus"
                      className="form-control form-control-sm mt-1"
                      value={formData.description_bus}
                      placeholder="Enter business description"
                      rows="2"
                      onChange={handleChange}
                    />
                  </div>
                  <FUSButton
                    labelText="Save"
                    buttonType="primary"
                    onClick={handleEdit}
                  />
                </div>
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};
export default TitleConfiguration;

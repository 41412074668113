import React, { useEffect, useState } from "react";
import TokenService from "../../services/token.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import _ from "lodash";
import DatePicker from "react-datepicker";
import VendorService from "../../services/vendor.services";
import { toast } from "react-toastify";
import util from "../../services/util";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";

const VendorDiscount = () => {
  const token = TokenService.getUser();
  const vendorID = token?.vendor?.id;

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [allDiscount, setAllDiscounts] = useState([]);
  const [objIndex, setIndex] = useState(null);
  const [lobOptions, setLobOptions] = useState([]);
  const [discountObject, setDiscountObject] = useState({
    lob_id: null,
    discount_name: null,
    discount_type: null,
    frequency: null,
    usr_app_duration: null,
    discount_value: null,
    max_discount_value: null,
    min_txn_value: null,
    start_date: new Date(),
    end_date: new Date(),
    terms: null,
    description: null,
  });

  const [errors, setErrors] = useState({});
  const handleCancel = async () => {
    setIndex(null);
    setDiscountObject({
      lob_id: null,
      discount_name: null,
      discount_type: null,
      frequency: null,
      usr_app_duration: null,
      discount_value: null,
      max_discount_value: null,
      min_txn_value: null,
      start_date: new Date(),
      end_date: new Date(),
      terms: null,
      description: null,
    });
    setRefresh(!refresh);
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  const findFormErrors = () => {
    const { start_date,end_date } = discountObject;
    const newErrors = {};
   
    if (start_date >= new Date(end_date)) {
      newErrors.end_date = "End Date cannot be before the Start Date ";
    } 
    else {
      newErrors.end_date = "";
    } 
   
    return newErrors;
  };

  const handleSubmit = async () => {
    const data = {
      lob_id: discountObject?.lob_id,
      frequency: discountObject?.frequency,
    };
    const checkNullEmptyValues = (obj) => {
      const errors = {};
      _.forOwn(obj, (value, key) => {
        if (_.isNil(value) || value === "") {
          errors[key] = `${_.startCase(key)} is required`;
        }
      });
      return errors;
    };
    const dataError = checkNullEmptyValues(data);
    if (_.isEmpty(dataError)) {
      if (discountObject?.start_date >= discountObject?.end_date) {
        const newErrors = findFormErrors();
        if (Object.keys(newErrors).length > 0) { 
        
          setErrors(newErrors);  
        } 
        const successToast = toast.warn("Please Choose Correct Date", {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } 
      else {
        try {
          const result = await VendorService.addDiscountByVendor(
            discountObject
          );
          if (result?.data?.errorCode === -409) {
            toast.error(result?.data?.message);
          } else if (result?.data?.errorCode === 0) {
            const successToast = toast.success(`${result?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);

            setDiscountObject({
              lob_id: null,
              discount_name: null,
              discount_type: null,
              frequency: null,
              usr_app_duration: null,
              discount_value: null,
              max_discount_value: null,
              min_txn_value: null,
              start_date: new Date(),
              end_date: new Date(),
              terms: null,
              description: null,
            });
            setRefresh(!refresh);
            setShowModal((prev) => ({ ...prev, state: false }));
            setErrors({});
          }
        } catch (error) {
          requestAndErrorHandler.errorHandler(error);
        }
      }
    } else {
      setErrors(dataError);
    }
  };

  const getAllDiscount = async () => {
    try {
      const result = await VendorService.getAllDiscountOfVendor();
      setAllDiscounts([]);
      if (result?.data?.errorCode === 0) {
        setAllDiscounts(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleEdit = (obj, itemId) => {
    setShowModal((prev) => ({ ...prev, state: true }));
    setDiscountObject(obj);
    setIndex(itemId);
  };

  const dateConverter = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const newDate = [date.getFullYear(), mnth, day].join("-");
    return newDate;
  };

  const handleUpdate = async () => {
    const discountToUpdate = {
      ...discountObject,
      start_date: dateConverter(discountObject.start_date),
      end_date: dateConverter(discountObject.end_date),
    };
    
    if (discountToUpdate?.start_date >= discountToUpdate?.end_date) {
      const newErrors = findFormErrors();
      if (Object.keys(newErrors).length > 0) { 
        setErrors(newErrors);  
      } 
      const successToast = toast.warn("Please Choose Correct Date", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      try {
        const result = await VendorService.updateDiscountByVendor(
          discountToUpdate
        );
        if (result?.data?.errorCode === 0) {
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setRefresh(!refresh);
          setIndex(null);
          setDiscountObject({
            lob_id: null,
            discount_name: null,
            discount_type: null,
            frequency: null,
            usr_app_duration: null,
            discount_value: null,
            max_discount_value: null,
            min_txn_value: null,
            start_date: new Date(),
            end_date: new Date(),
            terms: null,
            description: null,
          });
          setShowModal((prev) => ({ ...prev, state: false }));
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    };
  }
  const handleDelete = async (id, vendor_id) => {
    try {
      const result = await VendorService.deleteDiscountByVendor({
        id,
        vendor_id,
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);

        setRefresh(!refresh);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleModalClose = (second) => {
    setErrors({});
    setShowModal((prev) => ({ ...prev, state: false }));
    setIndex(null);
    setDiscountObject({
      lob_id: null,
      discount_name: null,
      discount_type: null,
      frequency: null,
      usr_app_duration: null,
      discount_value: null,
      max_discount_value: null,
      min_txn_value: null,
      start_date: new Date(),
      end_date: new Date(),
      terms: null,
      description: null,
    });
  };

  useEffect(() => {
    getAllDiscount();
  }, [refresh]);

  useEffect(() => {
    VendorService.getAllCouponLOB({ vendor_id: vendorID })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setLobOptions(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  return (
    <>
      <LayoutContainer
        title1="Offers"
        title2="Offers"
        title3="Manage Your  Coupons"
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add Discount"}
              onClick={() =>
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "Discount",
                }))
              }
            />
          </>
        }
      >
        <div>
          <div className="border_bottom_style">
            <div className="row justify-content-between">
              <div className="col-md-6"></div>
            </div>
          </div>

          <div className="px-3 mt-3">
            <table className="table table-bordered border border-1">
              <thead>
                <tr>
                  <th>Discount Name</th>
                  <th>LOB Name</th>
                  <th>Discount Value</th>
                  <th>Discount Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Max Discount Value</th>
                  <th>Terms</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(allDiscount) &&
                  allDiscount.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td> {item?.discount_name} </td>
                          <td> {item?.lob} </td>
                          <td> {item?.discount_value} </td>
                          <td> {item?.discount_type} </td>
                          <td> {util?.dateFormatter(item?.start_date)} </td>
                          <td> {util?.dateFormatter(item?.end_date)} </td>
                          <td> {item?.max_discount_value} </td>
                          <td> {item?.terms} </td>
                          <td> {item?.description} </td>
                          <td>
                            <div className="d-flex gap-2">
                              {objIndex === item?.id ? (
                                <></>
                              ) : (
                                <FUSButton
                                  className={"action_button_style"}
                                  iconSrc="md"
                                  iconName="MdOutlineModeEdit"
                                  iconSize={16}
                                  buttonType="lightgray"
                                  onClick={() => handleEdit(item, item?.id)}
                                />
                              )}

                              <FUSButton
                                className={"action_button_style"}
                                iconSrc="md"
                                iconName="MdDeleteForever"
                                iconSize={15}
                                buttonType="lightdanger"
                                onClick={() =>
                                  handleDelete(item?.id, token?.vendor?.id)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        {showModal.state ? (
          <FUSModal
            title={"Add Discount Details"}
            showModal={showModal.state}
            size={"lg"}
            handleClose={() => handleModalClose()}
            centered
          >
            <div className="px-3">
              <span className="text-secondary" htmlFor="">
                Select LOB
              </span>
              <select
                className="form-control text_style mt-1"
                value={discountObject?.lob_id}
                onChange={(e) =>
                  setDiscountObject((prev) => ({
                    ...prev,
                    lob_id: e.target.value,
                  }))
                }
                disabled={objIndex ? true : false}
              >
                <option value={null}>Select LOB </option>
                {!_.isEmpty(lobOptions) &&
                  lobOptions.map((val, index) => (
                    <option key={index} value={val?.lob_id}>
                      {val?.lob}
                    </option>
                  ))}
              </select>
              {discountObject?.lob_id === "" ||
                discountObject?.lob_id === null ||
                discountObject?.lob_id === undefined ? (
                <span className="error-text">{errors?.lob_id}</span>
              ) : null}
            </div>
            <>
              <div className="row justify-content-between">
                <div className="col-md-12 mt-4">
                  <label htmlFor="">Discount Name</label>
                  <input
                    type="text"
                    name="discount_name"
                    id=""
                    className="form-control"
                    placeholder="Enter Discount Name"
                    value={discountObject?.discount_name}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        discount_name: e.target.value,
                      }));
                    }}
                    disabled={objIndex ? true : false}
                  />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-4 mt-4">
                  <label htmlFor="">Discount Start Date</label>
                  <br />
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    selected={
                      new Date(discountObject?.start_date) || new Date()
                    }
                    value={new Date(discountObject?.start_date) || new Date()}
                    onChange={(date) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        start_date: date,
                      }));
                    }}
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <label htmlFor="">Discount End Date</label> <br />
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    selected={new Date(discountObject?.end_date) || new Date()}
                    value={new Date(discountObject?.end_date) || new Date()}
                    minDate={discountObject?.start_date}
                    onChange={(date) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        end_date: date,
                      }));
                    }}
                  />
                    {errors?.end_date && (
                      <p className="text-danger">{errors?.end_date}</p>
                    )}
                </div>
                <div className="col-md-4 mt-4">
                  <label htmlFor="">Discount Type</label>
                  <select
                    name=""
                    id=""
                    className="form-control"
                    value={discountObject?.discount_type}
                    onChange={(e) =>
                      setDiscountObject((prev) => ({
                        ...prev,
                        discount_type: e.target.value,
                      }))
                    }
                  >
                    <option selected>Select Discount Type</option>
                    <option value="percentage">PERCENTAGE</option>
                    <option value="amount">AMOUNT</option>
                  </select>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-6 mt-4">
                  <label htmlFor="">Discount Value</label>
                  <input
                    type="number"
                    name="frequency"
                    id=""
                    className="form-control"
                    placeholder="Enter Discount Value"
                    value={discountObject?.discount_value}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        discount_value: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="col-md-6 mt-4">
                  <label htmlFor="">Discount Frequency</label>
                  <input
                    type="number"
                    name="frequency"
                    id=""
                    className="form-control"
                    placeholder="Enter Discount Frequency"
                    value={discountObject?.frequency}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        frequency: e.target.value,
                      }));
                    }}
                  />
                  {discountObject?.frequency === "" ||
                    discountObject?.frequency === null ||
                    discountObject?.frequency === undefined ? (
                    <span className="error-text">{errors?.frequency}</span>
                  ) : null}
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-md-6 mt-4">
                  <label htmlFor="">User App Duration</label>
                  <input
                    type="number"
                    className="form-control"
                    name="description"
                    id="description"
                    rows="3"
                    placeholder="Please write here..."
                    value={discountObject?.usr_app_duration}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        usr_app_duration: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="col-md-6 mt-4">
                  <label htmlFor="">Max Discount Value</label>
                  <input
                    type="number"
                    min={0}
                    className="form-control"
                    name="description"
                    id="description"
                    rows="3"
                    placeholder="Please write here..."
                    value={discountObject?.max_discount_value}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        max_discount_value: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-md-12 mt-4">
                  <label htmlFor="">Min Transaction Value</label>
                  <input
                    type="number"
                    min={0}
                    className="form-control"
                    placeholder="Please write here..."
                    value={discountObject?.min_txn_value}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        min_txn_value: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-12 mt-4">
                  <label htmlFor="">Terms</label>
                  <textarea
                    rows={3}
                    min={0}
                    className="form-control"
                    placeholder="Please write here..."
                    value={discountObject?.terms}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        terms: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-12 mt-4">
                  <label htmlFor="">Discription</label>
                  <textarea
                    rows={3}
                    min={0}
                    className="form-control"
                    placeholder="Please write here..."
                    value={discountObject?.description}
                    onChange={(e) => {
                      setDiscountObject((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="row justify-content-end align-items-end">
                <div className="col-md-12 my-3 text-end d-flex gap-3 align-items-center justify-content-end">
                  <FUSButton
                    labelText={"Cancel"}
                    buttonType="secondary"
                    onClick={handleCancel}
                  />
                  {_.isNull(objIndex) ? (
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      onClick={handleSubmit}
                    ></FUSButton>
                  ) : (
                    <FUSButton
                      labelText={"Update"}
                      buttonType="primary"
                      onClick={handleUpdate}
                    ></FUSButton>
                  )}
                </div>
              </div>
            </>
          </FUSModal>
        ) : null}
      </LayoutContainer>
    </>
  );
};

export default VendorDiscount;

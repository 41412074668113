import api from "./api";

const getPartyDetails = () => {
  return api.get("/common/getPartyDetails");
};

const get_party_by_agency_id = (agency_id) => {
  return api.get(`/get_party_by_agency_id?agency_id=${agency_id}`);
};
const getAllLeadsByAgencyId = (agency_id) => {
  return api.get(`/getAllLeadsByAgencyId`, agency_id);
};

const get_visit_details = (party_id) => {
  return api.get(`/get_visit_details?party_id=${party_id}`);
};

const getPartyNamebyAgencyId = () => {
  return api.post("/get-visit-party");
};

const getLeadsByAgencyId = (params) => {
  return api.get(
    `/get_leads_by_agency_id?agency_id=${params?.agency_id}&start_date='${params?.start_date}'&end_date='${params?.end_date}'&flag=${params?.flag}`
  );
};

const getVisitsOnLeadsAndParties = (params) => {
  return api.get(
    `/get_all_visits_on_lead?agency_id=${params?.agency_id}&start_date='${params?.start_date}'&end_date='${params?.end_date}'`
  );
};

const getPartiesByAgencyId = (params) => {
  return api.get(
    `/getPartiesByAgencyId?agency_id=${params?.agency_id}&start_date='${params?.start_date}'&end_date='${params?.end_date}'&flag=${params?.flag}`
  );
};

const getAllLeadsConvertedToParties = (params) => {
  return api.get(
    `/getAllLeadsConvertedToParties?agency_id=${params?.agency_id}&start_date='${params?.start_date}'&end_date='${params?.end_date}'&flag=${params?.flag}`
  );
};

const getLeadAndPartyMetrics = (params) => {
  return api.get(`/getLeadAndPartyMetrics?agency_id=${params?.agency_id}`);
};

const VisitService = {
  get_visit_details,
  get_party_by_agency_id,
  getPartyNamebyAgencyId,
  getPartyDetails,
  getAllLeadsByAgencyId,
  getLeadsByAgencyId,
  getVisitsOnLeadsAndParties,
  getPartiesByAgencyId,
  getAllLeadsConvertedToParties,
  getLeadAndPartyMetrics,
};

export default VisitService;

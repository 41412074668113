import React, { useEffect, useState, useCallback } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TokenService from "../../../services/token.service";
import VARIABLES from "../../../services/variable.service";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./Holidays.css";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import FileSaver from "file-saver";
import { HOLIDAY_EXCEL_BASE64 } from "../../../services/sample_file";
function Holidays() {
  const getTomorrowDate = () => {
    var d = new Date();
    d.setDate(d.getDate() + 1);
    return d;
  };
  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  // const [fromDate, setFromDate] = useState(new Date());
  // const [toDate, setToDate] = useState(new Date());
  const [showHolidayList, setShowHolidayList] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [showModal, setShowModal] = useState({
    state: false,
    type: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    holiday_name: "",
    holiday_date: getTomorrowDate(),
    office_loc: "",
    shifts: "All Shifts",
    type: "ALL",
    is_active: "",
  });

  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 });

  useEffect(() => {
    MstService.crudAgencyHoliday({ type: "ALL" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowHolidayList(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddHoliday = () => {
    const dataToAdd = {
      ...formData,
      holiday_date: getISODate(formData.holiday_date),
    };

    MstService.crudAgencyHoliday({ ...dataToAdd, type: "INSERT" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const successToast = toast.success(`${res.data.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setFormData({
            holiday_name: "",
            holiday_date: getTomorrowDate(),
            office_loc: "",
            shifts: "All Shifts",
            type: "ALL",
            is_active: "",
          });
          setShowModal((prev) => ({ ...prev, state: false }));
          setIsLoading((isLoading) => !isLoading);
        } else if (res?.data?.errorCode === -409) {
          const errorToast = toast.error(`${res.data.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditClick = (id) => {
    setIsEdit(true);

    MstService.crudAgencyHoliday({ type: "GET", id: id })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const editFormData = { ...res.data.data[0] };
          editFormData.holiday_date = new Date(editFormData.holiday_date);
          setFormData(editFormData);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditHoliday = () => {
    MstService.crudAgencyHoliday({
      ...formData,
      holiday_date: getISODate(formData.holiday_date),
      type: "UPDATE",
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const successToast = toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setIsLoading((isLoading) => !isLoading);
          setShowModal((prev) => ({ ...prev, state: false }));
          setFormData({
            holiday_name: "",
            holiday_date: getTomorrowDate(),
            office_loc: "",
            shifts: "All Shifts",
            type: "ALL",
            is_active: "",
          });
          setIsEdit(false);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDelete = useCallback((id) => {
    const isDelete = window.confirm(`Are you want to delete holiday`);
    if (isDelete) {
      MstService.crudAgencyHoliday({ id: id, type: "DELETE" })
        .then((response) => {
          if (
            response?.data?.errorCode === 201 ||
            response?.data?.errorCode === 200 ||
            response?.data?.errorCode === 0
          ) {
            setShowHolidayList((oldValues) => {
              return oldValues.filter((item) => item.id !== id);
            });
            const successToast = toast.success(`${response?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          }
          if (
            response?.data?.errorCode === -101 ||
            response?.data?.errorCode === -102 ||
            response?.data?.errorCode === -103 ||
            response?.data?.errorCode === -100
          ) {
            const successToast = toast.error(`${response?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  }, []);

  // for handling excel upload

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "PLease ensure your file constains holidays !"
      );
      if (!confirm) {
        setIsLoading((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      const successToast = toast.success("Please Upload a Excel file", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    }
  };
  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const strURL = `${VARIABLES.BASE_URL}uploadCrudAgencyHoliday`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);

    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsFilePicked(false);
            setIsLoading(!isLoading);
          }
          setShowModal((prev) => ({ ...prev, state: false }));
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(HOLIDAY_EXCEL_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample_holiday_file.xlsx"
    );
  };

  const columns = [
    {
      dataField: "holiday_name",
      text: "Holiday Name",
    },
    {
      dataField: "holiday_date",
      text: "Holiday Date",
    },
    {
      dataField: "shifts",
      text: "Shifts",
    },
    {
      dataField: "office_loc",
      text: "Location",
    },
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <span className="text-success">Active</span>
            ) : (
              <span className="text-danger">Inactive</span>
            )}
          </>
        );
      },
      editable: false,
    },

    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => {
                    setShowModal((prev) => ({
                      ...prev,
                      state: true,
                    }));
                    handleEditClick(row.id);
                  }}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row.id)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "7",
        value: 7,
      },
      {
        text: "14",
        value: 14,
      },
      {
        text: "All",
        value: showHolidayList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1={"Settings"}
        title2={"Settings"}
        title3={"Holidays"}
        right={
          <>
            <div className="d-flex text-center pt-1 align-items-center">
              <span className="beat_file_sample d-none d-sm-block">
                Upload holiday file in
              </span>
              &nbsp;
              <a
                className="beat_file_sample themeColorBlue"
                style={{ cursor: "pointer" }}
                onClick={handleDownload}
              >
                Link sample holiday format file
              </a>
            </div>
          </>
        }
      >
        <div className="py-2">
          <div className="border_bottom_color px-2">
            <div className="row ">
              <div className="col-12 d-flex justify-content-sm-end gap-3">
                <FUSButton
                  iconSrc={"fa6"}
                  iconName={"FaPlus"}
                  iconSize={14}
                  labelText={isMobile ? "New Holiday" : "Add New Holiday"}
                  buttonType="secondary"
                  onClick={() => {
                    setShowModal((prev) => ({
                      ...prev,
                      state: true,
                    }));
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      type: "INSERT",
                    }));
                  }}
                />

                <FUSButton
                  iconSrc={"md"}
                  iconName={"MdOutlineFileDownload"}
                  iconSize={19}
                  labelText={isMobile ? "Holiday List" : "Import Holiday List"}
                  buttonType="primary"
                  onClick={() =>
                    setShowModal((prev) => ({
                      ...prev,
                      state: true,
                      type: "UPLOAD",
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="col-lg-11 col-12">
              <BootstrapTable
                classes="border border-1"
                keyField="id"
                data={showHolidayList}
                columns={columns}
                sizePerPage={5}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            </div>
          </div>
          {/* modal */}

          {showModal.state ? (
            <FUSModal
              title={
                showModal.type === "UPLOAD"
                  ? `Add Holiday List`
                  : `${isEdit ? "Edit Holiday" : "Add Holiday"}`
              }
              showModal={showModal.state}
              handleClose={() => {
                setIsEdit(false);
                setFormData({
                  holiday_name: "",
                  holiday_date: getTomorrowDate(),
                  office_loc: "",
                  shifts: "All Shifts",
                  type: "ALL",
                  is_active: "",
                });
                setShowModal((prev) => ({ ...prev, state: false }));
                setSelectedFile();
                setIsFilePicked(false);
              }}
              centered
              size="md"
            >
              {showModal.type === "UPLOAD" ? (
                <Form>
                  <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                      <div>
                        <div className="row d-flex justify-content-center mt25">
                          <div className="col-md-auto">
                            <Form.Group controlId="formFileLg" className="mb-3">
                              <Container>
                                <Row className="row-bottom-margin">
                                  <Form.Group as={Col}>
                                    <Form.Label>Upload Holiday List</Form.Label>
                                    <Form.Control
                                      type="file"
                                      size="sm"
                                      onChange={changeHandler}
                                      accept=".xls,.xlsx"
                                    />
                                  </Form.Group>
                                </Row>
                                <Row className="row-bottom-margin mt25">
                                  {isFilePicked && (
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        {isFilePicked ? (
                                          <div>
                                            <p className="p0">
                                              {`Filename: ${selectedFile?.name} `}{" "}
                                            </p>
                                            <p className="p0">
                                              {`  Filetype: ${selectedFile?.type} `}{" "}
                                            </p>
                                            <p className="p0">
                                              {`  Size in bytes: ${selectedFile?.size}`}{" "}
                                            </p>
                                            <p className="p0">
                                              {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}{" "}
                                            </p>
                                          </div>
                                        ) : (
                                          <span>
                                            Select a file to show details
                                          </span>
                                        )}
                                      </Form.Label>
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                      <Button
                                        variant="primary"
                                        className="btn btn-sm"
                                        onClick={handleSubmission}
                                      >
                                        Upload
                                      </Button>
                                    </Form.Group>
                                  )}
                                </Row>
                              </Container>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                  <div className="mt-3">
                    {formData.expense_type ? (
                      <FUSButton
                        className={"form-control"}
                        buttonType="primary"
                        onClick={isEdit ? handleEditHoliday : handleAddHoliday}
                        labelText={"Save Changes"}
                      />
                    ) : null}
                  </div>
                </Form>
              ) : (
                <Form>
                  <Row className="">
                    <Col md={12} className="px-0">
                      <div>
                        <span className="label_style">Holiday Name</span>
                        <Form.Control
                          type="text"
                          name="holiday_name"
                          value={formData.holiday_name}
                          onChange={handleChange}
                          className="py-2 my-1 text_style"
                          required={true}
                          placeholder="Enter Holiday Name"
                        />
                      </div>
                    </Col>
                    <Col md={12} className="px-0">
                      <div className="mt-2">
                        <span className="label_style">Location</span>
                        <Form.Control
                          type="text"
                          name="office_loc"
                          value={formData.office_loc}
                          onChange={handleChange}
                          className="py-2 my-1 text_style"
                          required={true}
                          placeholder="Enter Office Location"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="px-0">
                      <div className="mt-2">
                        <span className="label_style">Status</span>
                        <Form.Select
                          className="py-2 my-1 text_style"
                          name="is_active"
                          value={formData.is_active}
                          onChange={handleChange}
                        >
                          <option value={"true"}>Activate</option>
                          <option value={"false"}>Deactivate</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md={4} className="mt-2 ps-0">
                      <div>
                        <span className="label_style">Start Date </span>
                        <br />
                        <DatePicker
                          className="px-3 py-2 border border-1 rounded rounded-1 mt-1"
                          dateFormat="yyyy-MM-dd"
                          selected={formData.holiday_date}
                          minDate={getTomorrowDate()}
                          maxDate={null}
                          onChange={(date) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              holiday_date: date,
                            }));
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={7} className="mt-2 px-0">
                      <div>
                        <span className="label_style">Select Shift </span>
                        <Form.Select
                          className="px-3 py-2 border border-1 rounded rounded-1 mt-1"
                          name="shifts"
                          value={formData.shifts}
                          onChange={handleChange}
                        >
                          <option value={"All Shifts"}>All Shifts</option>
                          <option value={"Day Shift"}>Day Shift</option>
                          <option value={"Night Shift"}>Night Shift</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-3">
                    <FUSButton
                      className={"form-control"}
                      buttonType="primary"
                      disabled={!(formData.holiday_name && formData.office_loc)}
                      onClick={isEdit ? handleEditHoliday : handleAddHoliday}
                      labelText={"Save Changes"}
                    />
                  </div>
                </Form>
              )}
            </FUSModal>
          ) : null}
        </div>
      </LayoutContainer>
    </>
  );
}

export default Holidays;

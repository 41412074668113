import React, { useContext, useEffect, useState } from "react";
import VendorService from "../../services/vendor.services";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";
import { StoreContext } from "../../store/StoreContext";
import TokenService from "../../services/token.service";
import { checkfalsyValue } from "../../validation/validation";
import _ from "lodash";

const VendorSlotConfiguration = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [deleteModal, setDeleteModal] = useState({
    state: false,
    type: "",
    data: null,
  });
  const [vendorList, setVendorList] = useState([]);
  const [deliverySlots, setDeliverySlots] = useState([]);
  const [orderConfigDetail, setOrderConfig] = useState({});
  const [reload, setReload] = useState(true);
  const [slotData, setSlotData] = useState([]);
  const [vendorTime, setVendorTimes] = useState([]);
  const [errors, setErrors] = useState("");
  const [times, setTimes] = useState({
    start_date: "",
    end_date: "",
    slot_id: "",
    order_time_id: "",
  });
  const [deliveryTimes, setDeliveryTimes] = useState({
    start_date: "",
    end_date: "",
    slot_id: "",
  });
  const token = TokenService.getUser();
  const [vendorID, setVendorID] = useState();
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [slotId, setSlotID] = useState(null);
  const getSlotsAndTime = async () => {
    await VendorService.getAllSystemSlotsAndTime()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const filterData = res?.data?.data?.filter(
            (item) => item?.slot_id === "SLT0000001"
          );
          setDeliverySlots(filterData);
          setTimes({
            slot_id: filterData[0]?.slot_id,
            start_date: filterData[0]?.start_time,
            end_date: filterData[0]?.end_time,
          });
          setSlotData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const getVendorLocation = async () => {
    await VendorService.getVendorLocation({ vendor_user_id: vendorID })
      .then((res) => {
        setLocationData([]);
        setSelectedLocation(null);
        if (res?.data?.errorCode === 0) {
          setLocationData(res?.data?.data);
          if (res?.data?.data.length > 0) {
            setSelectedLocation(res?.data?.data[0]?.id);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const getVendorSlotTimings = async (locationId) => {
    await VendorService.getVendorOrderTimeConfigonLocation({
      vendor_user_id: vendorID,
      location_id: locationId,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorTimes(res?.data?.data?.order_range);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const constructDateFromTime = (time) => {
    const currentDate = new Date();
    const [hours, minutes] = time?.split(":");
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes
    );
  };

  const generateStartTimeOptions = (startTime, endTime) => {
    const start = constructDateFromTime(startTime);
    const end = constructDateFromTime(endTime);

    const timeOptions = [];

    while (start <= end) {
      timeOptions.push({
        label: start.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),

        value: `${start.getHours()}:00:00`,
      });
      start.setMinutes(start.getMinutes() + 60);
    }

    return timeOptions;
  };

  const generateEndTimeOptions = (end, start, item) => {
    const endDate = constructDateFromTime(end);
    const startDate = constructDateFromTime(start);
    const timeOptions = [];
    while (endDate >= startDate) {
      timeOptions.push({
        label: endDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        value: `${endDate.getHours()}:00:00`,
      });
      endDate.setMinutes(endDate.getMinutes() - 60);
    }

    return timeOptions;
  };
  const handleVendorSelection = (value) => {
    if (value !== -1) {
      setVendorID(value);
    } else {
      setLocationData([]);
      setSelectedLocation(null);
      setVendorID(null);
    }
  };
  function handleDateSelection(value, id, flag) {
    if (flag === "start_date") {
      setTimes((prev) => ({
        ...prev,
        start_date: value,
        slot_id: id,
      }));
    } else {
      setTimes((prev) => ({
        ...prev,
        end_date: value,
      }));
    }
  }
  function handleDeliverySelection(value, id, flag) {
    if (flag === "start_date") {
      setDeliveryTimes((prev) => ({
        ...prev,
        start_date: value,
        slot_id: id,
      }));
    } else {
      setDeliveryTimes((prev) => ({
        ...prev,
        end_date: value,
      }));
    }
  }

  const handleSelectLocation = (value) => {
    setSelectedLocation(value);
  };

  const handleSelect = (value) => {
    if (value !== -1) {
      const filteredSlots = slotData.filter((slot) => value === slot.slot_id);
      setDeliverySlots(filteredSlots);
      setTimes({
        slot_id: filteredSlots[0]?.slot_id,
        start_date: filteredSlots[0]?.start_time,
        end_date: filteredSlots[0]?.end_time,
      });
    } else {
      setDeliverySlots([]);
    }
  };

  const handleSaveOrderSlot = async () => {
    const requestData = {
      slot_id: times.slot_id,
      start_time: times.start_date,
      end_time: times.end_date,
      vendor_location_id: selectedLocation,
      vendor_id: vendorID,
    };

    await VendorService.insertOrderTimeConfigByVendor(requestData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowModal({ state: false });
          const successToast = toast.success(`Slot Added Successfully`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          getVendorSlotTimings(selectedLocation, vendorID);
        } else if (res?.data?.errorCode === -409) {
          const conflictToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(conflictToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
        setReload((val) => !val);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const displaySuccessToast = (message) => {
    const successToast = toast.success(message, {
      position: "bottom-left",
      autoClose: false,
    });

    setTimeout(() => {
      toast.dismiss(successToast);
    }, 2000);
  };

  const mapOrderAndDeliverySlot = async (id, slot_id) => {
    try {
      const res = await VendorService?.manageDeliverySlots({
        id,
        slot_id,
      });
      if (res?.data?.errorCode === 0) {
        getVendorSlotTimings(selectedLocation, vendorID);
        setReload((reload) => !reload);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  const getAllVendors = async () => {
    try {
      const res = await VendorService.getAllVendors();
      if (res?.data?.errorCode === 0) {
        setVendorList(res?.data?.data);
      } else {
        setVendorList([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getAllVendors();
  }, [reload]);

  useEffect(() => {
    getSlotsAndTime();
  }, [reload, vendorTime]);

  useEffect(() => {
    getVendorLocation();
  }, [vendorID]);

  useEffect(() => {
    if (selectedLocation && vendorID) {
      getVendorSlotTimings(selectedLocation, vendorID);
    }
  }, [selectedLocation]);

  const handleSaveDeliverySlot = async () => {
    const requestData = {
      slot_id: deliveryTimes.slot_id,
      start_time: deliveryTimes.start_date,
      end_time: deliveryTimes.end_date,
      vendor_location_id: selectedLocation,
      order_time_id: orderConfigDetail?.order_time_id,
      vendor_id: vendorID,
    };

    const dataObj = {
      Time_Slot: deliveryTimes.slot_id,
      Start_Time: deliveryTimes.start_date,
      End_Time: deliveryTimes.end_date,
      Location: selectedLocation,
    };
    const validationError = checkfalsyValue(dataObj);
    setErrors(validationError);
    const convertTo24HrDate = (timeString) => {
      const [hours, minutes] = timeString.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    };

    const startDate = convertTo24HrDate(deliveryTimes.start_date);
    const endDate = convertTo24HrDate(deliveryTimes.end_date);

    if (_.isEmpty(validationError) && startDate < endDate) {
      await VendorService.addSlotTimingsByVendor(requestData)
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            setErrors({});
            setShowModal((prev) => ({ ...prev, state: false }));
            const successToast = toast.success(`Slot Added Successfully`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
            setShowModal((prev) => ({ ...prev, state: false }));
            mapOrderAndDeliverySlot(
              orderConfigDetail?.order_time_id,
              res?.data?.data[0]?.id
            );
          } else {
            const errorToast = toast.error(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(errorToast);
            }, 2000);
          }
          setReload((val) => !val);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    } else {
      setErrors((prev) => ({
        ...prev,
        timeError: "Start Time should not be greater than End Time",
      }));
    }
  };
  const updatedeliverySlots = async (slots, item) => {
    await VendorService.manageDeliverySlots({
      id: item?.order_time_id,
      slot_id: slots.slot_id,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReload((reload) => !reload);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDeleteOrderSlot = async (itemObj) => {
    try {
      const result = await VendorService.deleteOrderAndDeliveryTimeSlot({
        order_time_id: itemObj?.order_time_id,
      });
      if (result?.data?.errorCode === 0) {
        setDeleteModal((prev) => ({
          type: "",
          state: false,
          data: null,
        }));
        toast.success(result?.data?.message);
        setReload((val) => !val);
        getVendorSlotTimings(selectedLocation, vendorID);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  const handleDeleteDeliverySlot = async (order_time_id, delivery_time_id) => {
    try {
      const result = await VendorService.deleteDeliveryTimeSlot({
        order_time_id,
        delivery_time_id,
      });
      if (result?.data?.errorCode === 0) {
        setDeleteModal((prev) => ({
          ...prev,
          type: "",
          state: false,
          data: null,
        }));
        getVendorSlotTimings(selectedLocation, vendorID);
        toast.success(result?.data?.message);
        setReload((val) => !val);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };
  const handleEditDeliverySlot = async () => {
    try {
      if (!times.start_date || !times.end_date) {
        toast.error("Please select both start and end time.");
        return;
      }

      const result = await VendorService.editDeliverySlot({
        slotID: slotId,
        startTime: times.start_date,
        endTime: times.end_date,
      });
      if (result?.data?.errorCode === 0) {
        setShowModal({ state: false });
        toast.success(result?.data?.message);
        setReload((val) => !val);
        getVendorSlotTimings(selectedLocation, vendorID);
        setSlotID(null);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Delivery Slot Config"
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add Order Slots"}
              onClick={() =>
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "ADDSLOTS",
                }))
              }
            />
          </>
        }
      >
        <div className="px-3 py-3">
          <div className="row">
            <div className="col-md-3 mt-2">
              <select
                className="form-select text_style mt-1 mb-3"
                onChange={(e) => handleVendorSelection(e.target.value)}
              >
                <option value={-1}>Select Vendor</option>
                {vendorList?.length > 0 &&
                  vendorList?.map((item) => (
                    <option
                      key={item.vendor_user_id}
                      value={item.vendor_user_id}
                    >
                      {item.vendor_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3 mt-2">
              <select
                className="form-select text_style mt-1 mb-3"
                onChange={(e) => handleSelectLocation(e.target.value)}
                value={selectedLocation}
              >
                <option value={-1}>Select Location</option>
                {locationData?.length > 0 &&
                  locationData?.map((item) => (
                    <option key={item.id} value={item?.id}>
                      {item?.location_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <table className="table table-bordered border border-1">
              <thead>
                <th>Order Slot Id</th>
                <th>Order Start Time</th>
                <th>Order End Time</th>
                <th>Delivery Slots</th>
                <th>Action</th>
                <th>Delete Order Slots</th>
              </thead>
              <tbody>
                {vendorTime?.length > 0 ? (
                  vendorTime?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        {constructDateFromTime(
                          item?.order_start_time
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td>
                        {constructDateFromTime(
                          item?.order_end_time
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td>
                        {!_.every(item?.slot_details, (obj) =>
                          _.every(obj, (value) => _.isNil(value))
                        )
                          ? item?.slot_details?.map((slots) => (
                              <div className="row gy-1">
                                <div className="col-8">
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={slots?.is_active}
                                        onChange={(e) => {
                                          const newCheckedValue =
                                            e.target.checked;
                                          setVendorTimes((prevVendorTimes) =>
                                            prevVendorTimes.map((timeSlot) => {
                                              if (
                                                timeSlot.slot_id ===
                                                item.slot_id
                                              ) {
                                                return {
                                                  ...timeSlot,
                                                  slot_details:
                                                    timeSlot.slot_details.map(
                                                      (slot) =>
                                                        slot.slot_id ===
                                                        slots.slot_id
                                                          ? {
                                                              ...slot,
                                                              is_active:
                                                                newCheckedValue,
                                                            }
                                                          : slot
                                                    ),
                                                };
                                              }
                                              return timeSlot;
                                            })
                                          );
                                          updatedeliverySlots(slots, item);
                                        }}
                                      />
                                      {constructDateFromTime(
                                        slots?.start_time
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                      -
                                      {constructDateFromTime(
                                        slots?.end_time
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-2">
                                  <FUSButton
                                    className={"action_button_style"}
                                    iconSrc="md"
                                    iconName="MdOutlineDelete"
                                    iconSize={12}
                                    buttonType="danger"
                                    onClick={() =>
                                      setDeleteModal((prev) => ({
                                        ...prev,
                                        state: true,
                                        type: "DELETE_DELIVERY_SLOT",
                                        orderID: item?.order_time_id,
                                        slotID: slots?.slot_id,
                                      }))
                                    }
                                  />
                                </div>
                                <div className="col-2">
                                  <FUSButton
                                    className={"action_button_style"}
                                    iconSrc="md"
                                    iconName="MdOutlineEdit"
                                    iconSize={12}
                                    buttonType="primary"
                                    onClick={() => {
                                      setTimes({
                                        start_date: slots?.start_time,
                                        end_date: slots?.end_time,
                                        edit_slot_id: slots?.slot_id,
                                      });

                                      setSlotID(slots?.slot_id);
                                      setShowModal((prev) => ({
                                        ...prev,
                                        state: true,
                                        type: "EDITSLOTS",
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          : null}
                      </td>

                      <td>
                        <div className="row ">
                          <div className="col-md-auto">
                            <button
                              className={`btn btn-sm text-white px-3 fs-6
                            ${item?.is_active ? "btn-danger" : "btn-success"}`}
                              onClick={() => {
                                setOrderConfig(item);
                                setShowModal((prev) => ({
                                  ...prev,
                                  state: true,
                                  type: "ADD_DELIVERY_SLOT",
                                }));
                              }}
                            >
                              Add Delivery Slot
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="row ">
                          <div className="col-md-3">
                            <div className="col-2">
                              <FUSButton
                                className={"action_button_style"}
                                iconSrc="md"
                                iconName="MdOutlineDelete"
                                iconSize={12}
                                buttonType="danger"
                                onClick={() =>
                                  setDeleteModal((prev) => ({
                                    ...prev,
                                    state: true,
                                    type: "DELETE_ORDER_SLOT",
                                    itemData: item,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <div className="py-5 my-5">
                        <FUSEmptyList title="Data not found" />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* TO add config */}
        {showModal.state && (
          <FUSModal
            title={
              showModal.type === "EDITSLOTS"
                ? "Edit Delivery Slots"
                : showModal.type === "ADDSLOTS"
                ? "Add Order Slots"
                : "Add Delivery Slots"
            }
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false, type: "" }));
              setTimes({
                start_date: "",
                end_date: "",
                slot_id: "",
              });
            }}
            centered
          >
            {showModal.type === "ADDSLOTS" && (
              <div className="row">
                <div className="col-md-12 mt-2">
                  <select
                    className="form-select text_style mt-1 mb-3"
                    onChange={(e) => e.target.value}
                    value={selectedLocation}
                  >
                    <option value={-1}>Select Location</option>
                    {locationData?.length > 0 &&
                      locationData?.map((item) => (
                        <option key={item.id} value={item?.id}>
                          {item?.location_name}
                        </option>
                      ))}
                  </select>
                </div>

                {deliverySlots?.length > 0 &&
                  deliverySlots?.map((item, rowIndex) => (
                    <>
                      <div key={item.slot_id}>
                        <div className="col-md-12 px-0 mt-3">
                          <label>Select Start Time</label>
                          <select
                            className="form-select text_style mt-1"
                            onChange={(e) =>
                              handleDateSelection(
                                e.target.value,
                                item?.slot_id,
                                "start_date"
                              )
                            }
                          >
                            {generateStartTimeOptions(
                              item.start_time,
                              item.end_time
                            ).map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12 px-0 mt-3">
                          <label>Select End Time</label>
                          <select
                            className="form-select text_style mt-1"
                            onChange={(e) =>
                              handleDateSelection(
                                e.target.value,
                                rowIndex,
                                "end_date"
                              )
                            }
                          >
                            {generateEndTimeOptions(
                              item.end_time,
                              item.start_time
                            ).map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12 px-0 mt-3 mb-1">
                          <FUSButton
                            labelText={"Add Slot"}
                            className={"form-control"}
                            buttonType="primary"
                            onClick={() => handleSaveOrderSlot()}
                          ></FUSButton>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            )}

            {showModal.type === "ADD_DELIVERY_SLOT" && (
              <div className="row">
                <div className="col-md-12 mt-2">
                  <select
                    className="form-select text_style mt-1 mb-3"
                    value={selectedLocation}
                    onChange={(e) => {
                      handleSelectLocation(e.target.value);
                      setErrors({});
                    }}
                  >
                    <option value="">Select Location</option>
                    {locationData?.length > 0 &&
                      locationData?.map((item) => (
                        <option key={item.id} value={item?.id}>
                          {item?.location_name}
                        </option>
                      ))}
                  </select>
                  <p className="error-text">{errors?.Location}</p>
                </div>

                {deliverySlots?.length > 0 &&
                  deliverySlots?.map((item, rowIndex) => (
                    <>
                      <div key={item.slot_id}>
                        <div className="col-md-12 px-0 mt-3">
                          <p className="mb-0">Start Time</p>
                          <select
                            className="form-select text_style mt-1"
                            onChange={(e) => {
                              handleDeliverySelection(
                                e.target.value,
                                item?.slot_id,
                                "start_date"
                              );
                              setErrors({});
                            }}
                          >
                            {generateStartTimeOptions(
                              item.start_time,
                              item.end_time
                            ).map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <p className="error-text">{errors.timeError}</p>
                        </div>
                        <div className="col-md-12 px-0 mt-3">
                          <p className="mb-0">End Time</p>
                          <select
                            className="form-select text_style mt-1"
                            onChange={(e) =>
                              handleDeliverySelection(
                                e.target.value,
                                rowIndex,
                                "end_date"
                              )
                            }
                          >
                            {generateEndTimeOptions(
                              item.end_time,
                              item.start_time
                            ).map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12 px-0 mt-3 mb-1">
                          <FUSButton
                            labelText={"Add Delivery Slot"}
                            className={"form-control"}
                            buttonType="primary"
                            onClick={() => handleSaveDeliverySlot()}
                          ></FUSButton>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            )}

            {showModal.type === "EDITSLOTS" && (
              <div className="row">
                <>
                  <div>
                    <div className="col-md-12 px-0 mt-3">
                      <label>Select Start Time</label>
                      <select
                        className="form-select text_style mt-1"
                        onChange={(e) =>
                          handleDateSelection(
                            e.target.value,
                            times?.edit_slot_id,
                            "start_date"
                          )
                        }
                        value={times.start_date || ""}
                      >
                        {generateStartTimeOptions(
                          times.start_date,
                          times.end_date
                        ).map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12 px-0 mt-3">
                      <label>Select End Time</label>
                      <select
                        className="form-select text_style mt-1"
                        onChange={(e) =>
                          handleDateSelection(
                            e.target.value,
                            times?.edit_slot_id,
                            "end_date"
                          )
                        }
                        value={times.end_date || ""}
                      >
                        {generateEndTimeOptions(
                          times.end_date,
                          times.start_date
                        ).map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12 px-0 mt-3 mb-1">
                      <FUSButton
                        labelText={"Edit Delivery Slot"}
                        className={"form-control"}
                        buttonType="primary"
                        onClick={() => handleEditDeliverySlot()}
                      ></FUSButton>
                    </div>
                  </div>
                </>
              </div>
            )}
          </FUSModal>
        )}
        {deleteModal?.state && (
          <FUSModal
            title={
              deleteModal.type === "DELETE_DELIVERY_SLOT"
                ? "Delete Delivery Slot"
                : "Delete Order Slot"
            }
            showModal={deleteModal?.state}
            size={"md"}
            handleClose={() => {
              setDeleteModal((prev) => ({
                ...prev,
                state: false,
                type: "",
                data: null,
              }));
              setTimes({
                start_date: "",
                end_date: "",
                slot_id: "",
              });
            }}
            centered
          >
            {deleteModal.type === "DELETE_DELIVERY_SLOT" &&
              deleteModal.state === true && (
                <>
                  <p>Do you want to delete this slot?</p>
                  <div className="modal-content border-0">
                    <div className="d-flex justify-content-between">
                      <FUSButton
                        className="form-control py-2 me-5"
                        buttonType="primary"
                        labelText="Yes"
                        onClick={() => {
                          handleDeleteDeliverySlot(
                            deleteModal?.orderID,
                            deleteModal?.slotID
                          );
                        }}
                      />
                      <FUSButton
                        className="form-control py-2"
                        buttonType="danger"
                        labelText="No"
                        onClick={() => {
                          setDeleteModal((prev) => ({
                            type: "",
                            state: false,
                            data: null,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

            {deleteModal.type === "DELETE_ORDER_SLOT" &&
              deleteModal.state === true && (
                <>
                  <p>Do you want to delete this slot?</p>
                  <div className="modal-content border-0">
                    <div className="d-flex justify-content-between">
                      <FUSButton
                        className="form-control py-2 me-5"
                        buttonType="primary"
                        labelText="Yes"
                        onClick={() =>
                          handleDeleteOrderSlot(deleteModal?.itemData)
                        }
                      />
                      <FUSButton
                        className="form-control py-2"
                        buttonType="danger"
                        labelText="No"
                        onClick={() => {
                          setDeleteModal((prev) => ({
                            type: "",
                            state: false,
                            data: null,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default VendorSlotConfiguration;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  OverlayView,
  OverlayViewF,
} from "@react-google-maps/api";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";

const MapComponent = ({ onLocationSelect }) => {
  const navigate = useNavigate();
  const [mapRef, setMapRef] = useState();
  const [defaultCenter, setDefaultCenter] = useState({});
  const [markers, setMarkers] = useState([]);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const mapStyles = {
    height: "60vh",
    width: "100%",
    
  };

  const handleMarkerClick = (
    id,
    lat,
    lng,
    name,
    user_row_id,
    mobile_number
  ) => {
    navigate("/collectorMap", { state: { item: { user_id: user_row_id } } });
    setSelectedMarker({ id, lat, lng, name, mobile_number });
    onLocationSelect(lat, lng, name);
  };

  const onLoad = (map) => {
    setMapRef(map);
  };

  const getCollectorLocation = async () => {
    MstService.getCollectorLocation({})
      .then((response) => {
        setMarkers(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };

  const getCurrentGeoLocation = async () => {
    const position = await getCurrentPosition();
    setDefaultCenter({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });
  };

  useEffect(() => {
    getCurrentGeoLocation();
    getCollectorLocation();
  }, []);

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setClickedLocation({ lat, lng });
    setSelectedMarker(null);
    onLocationSelect(lat, lng);
  };

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={13}
        center={defaultCenter}
        onLoad={onLoad}
        onClick={handleMapClick}
      >
        {markers.map(
          (
            {
              create_ts,
              latitude,
              longitude,
              mobile_number,
              name,
              user_row_id,
            },
            ind
          ) => (
            <Marker
              key={ind}
              position={{
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              }}
              draggable={false}
              title={name}
              onClick={() => {
                handleMarkerClick(
                  ind,
                  latitude,
                  longitude,
                  name,
                  user_row_id,
                  mobile_number
                );
              }}
            >
              <OverlayViewF
                key={ind}
                position={{
                  lat: parseFloat(latitude),
                  lng: parseFloat(longitude),
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  style={{
                    background: `#005ade`,
                    padding: `7px 12px`,
                    fontSize: "11px",
                    color: `#fff`,
                    borderRadius: "4px",
                  }}
                >
                  {name}
                </div>
              </OverlayViewF>
            </Marker>
          )
        )}

        {clickedLocation && (
          <OverlayViewF
            position={clickedLocation}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              style={{
                background: "rgba(0, 123, 255, 0.8)",
                padding: "5px 10px",
                color: "#fff",
                borderRadius: "4px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Latitude: {clickedLocation.lat.toFixed(6)} <br /> Longitude:{" "}
              {clickedLocation.lng.toFixed(6)}
            </div>
          </OverlayViewF>
        )}

        {selectedMarker && (
          <OverlayViewF
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              style={{
                background: "rgba(255, 193, 7, 0.8)",
                padding: "5px 10px",
                color: "#fff",
                borderRadius: "4px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              <div>{selectedMarker.name}</div>
              <div>{selectedMarker.mobile_number}</div>
              <div>Latitude: {selectedMarker.lat.toFixed(6)}</div>
              <div>Longitude: {selectedMarker.lng.toFixed(6)}</div>
            </div>
          </OverlayViewF>
        )}
      </GoogleMap>
    </>
  );
};

export default MapComponent;

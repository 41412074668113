import React from "react";
import "./FUSTabs.css";

const FUSTabs = ({ setTab, activeTab, tabOptions }) => {
  return (
    <div>
      <div className="tabs d-flex">
        {tabOptions?.length > 0 &&
          tabOptions?.map((tab, index) => {
            return (
              <div className="tab-buttons">
                <button
                  className={
                    activeTab?.toLowerCase() === tab?.value?.toLowerCase()
                      ? "active"
                      : "inactive"
                  }
                  onClick={() => setTab(tab?.value)}
                >
                  {tab?.label}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FUSTabs;

import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import VisitService from "../../services/VisitService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import TokenService from "../../services/token.service";
import { Col, Form } from "react-bootstrap";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSUploadFile from "../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import FileSaver from "file-saver";
import { PARTY_DATA } from "../../services/PartyMasterSample";
import DepartmentServices from "../../services/departmentServices";
import { toast } from "react-toastify";

const ManageParty = () => {
  const token = TokenService.getUser();
  const agency_id = token?.agent?.agent_user_id;
  const [partyData, setPartyData] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [reload, setReload] = useState(true);
  const [toggleModal, setToggleModal] = useState({
    state: false,
    type: null,
  });
  const columns = [
    {
      dataField: "party_name",
      text: "Party Name",
    },
    {
      dataField: "party_code",
      text: "Party Code",
      editable: false,
    },
    {
      dataField: "primary_mobile_no",
      text: "Mobile No.",
      editable: false,
    },
    {
      dataField: "address",
      text: "Address",
      editable: false,
    },
    {
      dataField: "postal_code",
      text: "Postal Code",
      editable: false,
    },
    {
      dataField: "longitude",
      text: "Longitude",
      editable: false,
    },
    {
      dataField: "latitude",
      text: "Latitude",
      editable: false,
    },
  ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: partyData.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  useEffect(() => {
    getPartyByAgencyId();
  }, [reload]);

  const getPartyByAgencyId = async () => {
    try {
      const result = await VisitService?.get_party_by_agency_id(agency_id);
      if (result?.data?.errorCode === 0) {
        setPartyData(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  const handleClose = () => {
    setToggleModal((prev) => ({ ...prev, state: false }));
    setSelectedFile();
    setIsFilePicked(false);
  };

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(PARTY_DATA);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "Party_Data_Sample.xlsx"
    );
  };

  const handleUploadParties = async () => {
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    await DepartmentServices.updatePartyLatLng(formData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReload(!reload);
          handleClose();
          const successToast = toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          handleClose();
          const errorToast = toast.error(
            res?.data?.message === "Failed to update."
              ? `${res?.data?.message}`
              : "Invalid Input Values",
            {
              position: "bottom-left",
              autoClose: false,
            }
          );
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  return (
    <>
      <LayoutContainer
        title1={"Visit Management"}
        title2={"Visit Management"}
        title3={"Manage Party"}
        right={
          <>
            <div className="d-flex text-center pt-1 align-items-center">
              <span className="beat_file_sample d-none d-sm-block">
                Upload party file in
              </span>
              &nbsp;
              <a
                className="beat_file_sample themeColorBlue"
                style={{ cursor: "pointer" }}
                onClick={handleDownload}
              >
                Link sample party format file
              </a>
            </div>
          </>
        }
      >
        <div className="px-1 py-2">
          <div className="border_bottom_color">
            <div className="row ">
              <div className="col-12 d-flex justify-content-sm-end gap-3">
                <FUSButton
                  iconSrc={"md"}
                  iconName={"MdOutlineFileDownload"}
                  iconSize={19}
                  labelText={"Import Party List"}
                  buttonType="primary"
                  onClick={() =>
                    setToggleModal((prev) => ({
                      ...prev,
                      state: true,
                      type: "",
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={partyData}
              columns={columns}
              sizePerPage={10}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
            />
          </div>
          {toggleModal.state ? (
            <FUSModal
              title={"Add Party Details"}
              showModal={toggleModal.state}
              size={"md"}
              handleClose={() => handleClose()}
              centered
            >
              <>
                <Form>
                  <div className="row d-flex justify-content-center mt25">
                    <div className="col-lg-auto">
                      <Form.Group controlId="formFileLg" className="mb-3">
                        <FUSUploadFile
                          title="Upload File"
                          onChange={changeHandler}
                        />
                        <div className="row-bottom-margin mt25">
                          {isFilePicked && (
                            <Form.Group as={Col}>
                              <Form.Label>
                                {isFilePicked ? (
                                  <div>
                                    <p className="p0">
                                      {`Filename: ${selectedFile?.name} `}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  Filetype: ${selectedFile?.type} `}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  Size in bytes: ${selectedFile?.size}`}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}{" "}
                                    </p>
                                  </div>
                                ) : (
                                  <span>Select a file to show details</span>
                                )}
                              </Form.Label>

                              <FUSButton
                                className={"form-control mt-3"}
                                buttonType="primary"
                                labelText={"Upload"}
                                onClick={handleUploadParties}
                              />
                            </Form.Group>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </>
            </FUSModal>
          ) : null}
        </div>
      </LayoutContainer>
    </>
  );
};

export default ManageParty;

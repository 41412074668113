/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import VendorService from "../../services/vendor.services";
import _ from "lodash";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import DatePicker from "react-datepicker";
import util from "../../services/util";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import "./VendorInvoice.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./VendorInvoice/Invoice";

const VendorInvoicing = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getTodayDay = () => {
    return new Date();
  };

  const currentYear = new Date().getFullYear();

  const [AllVendors, setAllVendors] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [vendor_id, setSelectedVendor_id] = useState("");
  const [vendor_user_id, setVendor_user_id] = useState("");

  const [fyDetails, setFYDetails] = useState({
    minDate: "",
    maxDate: "",
  });
  const [toggle, setToggle] = useState(false);
  const [LOB, setLOB] = useState("");
  const [viewData, setView] = useState(true);
  const [vendorConfig, setVendorConfig] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [selectedVendor, setSelectedVendorsDetail] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedDate, setDate] = useState("");
  const [selectedStartDate, setStartDate] = useState("");
  const [selectedEndDate, setEndDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [orderDetail, setOrderDetail] = useState([]);
  const [order_Date, setOrderDate] = useState("");
  const filterType = {
    date: "date",
    month: "month",
    year: "year",
    dateRange: "dateRange",
  };
  const [orderId, setOrderId] = useState(null);
  const [invoiceTransaction, setInvoiceTransactionDetails] = useState({});
  const [invoiceData, setInvoiceData] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const dateFormatter = (dates) => {
    const date = new Date(dates);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getAllVendors = async () => {
    try {
      const result = await VendorService.getAllVendors();
      if (result?.data?.errorCode === 0) {
        setAllVendors(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleVendorSelect = async (id) => {
    setView(true);
    setOrderInfo([]);
    const vendorDetails = AllVendors.find((item) => item.id === id);
    const vendor_user_id = vendorDetails ? vendorDetails.vendor_user_id : null;
    setSelectedVendor_id(id);
    setSelectedVendorsDetail(vendorDetails);
    setVendor_user_id(vendor_user_id);
  };

  const getVendorConfigByVendorId = async () => {
    try {
      const result = await VendorService.getVendorConfigByVendorId({
        vendor_id,
      });
      if (result?.data?.errorCode === 0) {
        setVendorConfig(result?.data?.data);
      } else {
        toast.warning("Please Add Transaction Config for Selected Vendor");
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleFY = (fy) => {
    const FY = fy.split("-");
    setFYDetails((prev) => ({
      ...prev,
      minDate: `${FY[0]}-04-01`,
      maxDate: `${FY[1]}-03-31`,
    }));
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getStartAndEndDateOfMonth(monthName) {
    const monthIndex = monthNames.indexOf(monthName);
    if (monthIndex === -1) {
      return "Invalid month name";
    }

    const year = new Date().getFullYear();
    const startDate = new Date(year, monthIndex, 1);
    const endDate = new Date(year, monthIndex + 1, 0);

    return {
      Start_Date: formatDate(startDate),
      End_Date: formatDate(endDate),
    };
  }

  const handleGetTransactionDetails = async (data) => {
    const { Start_Date, End_Date } = getStartAndEndDateOfMonth(selectedMonth);
    try {
      const result = await VendorService.getOrderByVendorIdAndLOB({
        vendor_user_id,
        start_date:
          selectedType === filterType?.month
            ? Start_Date
            : selectedType === filterType?.date
            ? formatDate(selectedDate)
            : selectedType === filterType?.dateRange
            ? formatDate(selectedStartDate)
            : fyDetails?.minDate,
        end_date:
          selectedType === filterType?.month
            ? End_Date
            : selectedType === filterType?.date
            ? formatDate(selectedDate)
            : selectedType === filterType?.dateRange
            ? formatDate(selectedEndDate)
            : fyDetails?.maxDate,
      });
      if (result?.data?.errorCode === 0) {
        setAllOrders(result?.data?.data);
      } else {
        setAllOrders([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleOrderInfo = async () => {
    const vendor_user_id = AllVendors.find(
      (item) => item.id === vendor_id
    )?.vendor_user_id;
    const { Start_Date, End_Date } = getStartAndEndDateOfMonth(selectedMonth);
    try {
      const result = await VendorService.getReceivedOrderInfoByVendor({
        vendor_id: vendor_user_id,
        start_date:
          selectedType === filterType?.month
            ? Start_Date
            : selectedType === filterType?.date
            ? formatDate(selectedDate)
            : selectedType === filterType?.dateRange
            ? formatDate(selectedStartDate)
            : fyDetails?.minDate,
        end_date:
          selectedType === filterType?.month
            ? End_Date
            : selectedType === filterType?.date
            ? formatDate(selectedDate)
            : selectedType === filterType?.dateRange
            ? formatDate(selectedEndDate)
            : fyDetails?.maxDate,
      });
      if (result?.data?.errorCode === 0) {
        setOrderInfo(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleOrderDetails = async (order_id) => {
    const orderObject = orderInfo.find(
      (orderObj) => orderObj.order_id === order_id
    );

    try {
      setOrderId(order_id);
      const result = await VendorService.getOrderItemsInfoByOrderId({
        order_id,
        lob_id: LOB,
      });
      if (result?.data?.errorCode === 0) {
        setOrderDetail(result?.data?.data);
        setOrderDate(orderObject);
        setShowModal((prev) => ({
          ...prev,
          state: true,
          type: " ",
        }));
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const findIndexofMonth = (monthArray, monthName) => {
    const ind = monthArray.indexOf(monthName);
    return ind;
  };

  const createInvTransInfo = (ord, ven) => {
    return ord.map((order) => ({
      ...order,
      vendor_name: ven.vendor_name,
      vendor_id: ven.id,
      month_name: selectedMonth,
      year: getTodayDay().getFullYear(),
      payable_commission:
        order.transaction_type === "Percentage"
          ? util?.calculatePercetage(order?.total_order_amount, order.amount)
          : parseInt(order?.amount) * parseInt(order?.total_order_count),
      total_order: order?.total_order_count,
    }));
  };

  const handleGenerateInvoice = async (ordersInfo, vendor) => {
    const dataArray = createInvTransInfo(ordersInfo, vendor);
    try {
      const result = await VendorService.generateInvoiceBySuperAdmin({
        transaction_data: dataArray,
      });
      if (result?.data?.errorCode === 0) {
        setInvoiceTransactionDetails(result?.data?.data);
        setToggle(!toggle);
        setView(true);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  const handleInvoiceDownloading = async (vendor_id) => {
    let vendorTransactionDetail;
    const result = await VendorService.updateTransactionIdOnPdfGeneration({
      transaction_id: invoiceData[0].transaction_id,
      vendor_id: invoiceData[0].vendor_id,
      month_name: invoiceData[0].month_name,
      year: invoiceData[0].year,
    });
    if (!_.isEmpty(invoiceTransaction)) {
      vendorTransactionDetail = invoiceTransaction?.invoiceTransData.find(
        (item) => item?.vendor_id === vendor_id
      );
    }
    if (!_.isEmpty(vendorTransactionDetail)) {
      try {
        const result = await VendorService.updateTransactionIdByAdmin(
          invoiceTransaction?.invoiceTransData[0]
        );

        if (result?.data?.errorCode === 0) {
          toast.success(result?.data?.message);
          setInvoiceTransactionDetails({});
        }
      } catch (error) {
        requestAndErrorHandler(error);
      }
    }
    setView(false);
  };

  const getInvoice = async () => {
    try {
      const result = await VendorService.getInvoiceTransactionDetailByAdmin({
        vendor_id,
        month_name: selectedMonth,
        year: getTodayDay().getFullYear(),
      });
      if (result?.data?.errorCode === 0) {
        setInvoiceData(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const isData = (vendor_id) => {
    const is_Data_Present =
      !_.isEmpty(allOrders) &&
      getTodayDay().getMonth() > findIndexofMonth(monthNames, selectedMonth);

    const is_Match = !_.isEmpty(
      invoiceData?.filter(
        (obj) => obj.month_name === selectedMonth && obj.vendor_id === vendor_id
      )
    );

    if (selectedType === "date" || selectedType === "year") {
      return null;
    } else {
      if (is_Data_Present) {
        if (is_Match) {
          if (viewData) {
            return (
              <td>
                <a href="#" onClick={() => handleInvoiceDownloading(vendor_id)}>
                  View
                </a>
              </td>
            );
          } else {
            return (
              <td>
                <div className="mt-4">
                  <PDFDownloadLink
                    document={
                      <Invoice
                        selectedVendor={selectedVendor}
                        invoiceData={invoiceData}
                        sumOfPayableCommission={parseInt(
                          Math.round(invoiceData[0]?.payable_commission)
                        )}
                        selectedMonth={selectedMonth}
                      />
                    }
                    fileName={`${selectedVendor?.vendor_name}-${selectedMonth}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Fetching document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </td>
            );
          }
        } else {
          return (
            <td>
              <FUSButton
                iconSrc={"fa"}
                iconName={"FaFilePdf"}
                iconSize={15}
                buttonType="danger"
                onClick={() => handleGenerateInvoice(allOrders, selectedVendor)}
              />
            </td>
          );
        }
      } else {
        return null;
      }
    }
  };

  let dueDate = new Date();
  dueDate.setDate(dueDate.getDate() + 3);

  const invoiceInfoArray = invoiceData[0]?.invoice_info || [];

  const sumOfPayableCommission = invoiceInfoArray.reduce((sum, infoItem) => {
    return sum + (infoItem.payable_commission || 0);
  }, 0);

  useEffect(() => {
    getInvoice();
  }, [selectedMonth, toggle, vendor_id]);

  useEffect(() => {
    getAllVendors();
  }, []);

  useEffect(() => {
    if (vendor_id) {
      getVendorConfigByVendorId();
    }
  }, [vendor_id]);

  useEffect(() => {
    if (!_.isEmpty(vendorConfig)) {
      handleGetTransactionDetails();
    }
  }, [
    fyDetails,
    selectedMonth,
    selectedDate,
    selectedEndDate,
    vendor_id,
    selectedType,
  ]);

  return (
    <>
      <LayoutContainer title1="Vendor" title2="Vendor" title3="Invoicing">
        <div className="px-1 py-1">
          <div className="border_bottom_style">
            <div className="row">
              <div className="col-md-3">
                <select
                  className="form-select text_style mt-1"
                  aria-label="Default select example"
                  name="vendor_id"
                  value={vendor_id}
                  onChange={(e) => handleVendorSelect(e.target.value)}
                >
                  <option selected>Select Vendor</option>
                  {AllVendors &&
                    AllVendors.map((vendor, i) => (
                      <>
                        <option value={vendor?.id} key={i}>
                          {vendor?.vendor_name}
                        </option>
                      </>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select text_style mt-1"
                  value={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                    setAllOrders([]);
                    setOrderInfo([]);
                    setInvoiceData([]);
                  }}
                >
                  <option value="">Select Option</option>
                  <option value={filterType.date}>Date</option>
                  <option value={filterType.month}>Month</option>
                  <option value={filterType.year}>Year</option>
                  <option value={filterType.dateRange}>Date Range</option>
                </select>
              </div>
              {selectedType === filterType.date ? (
                <div className="col-md-3">
                  <DatePicker
                    className="form-control  mt-1 text_style"
                    dateFormat="yyyy-MM-dd"
                    selected={selectedDate}
                    maxDate={getTodayDay()}
                    onChange={(date) => {
                      setDate(date);
                      setOrderInfo([]);
                    }}
                    placeholderText="Select Date"
                  />
                </div>
              ) : selectedType === filterType.month ? (
                <div className="col-md-3">
                  <select
                    className="form-select mt-1"
                    value={selectedMonth}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                      setView(true);
                      setOrderInfo([]);
                    }}
                  >
                    <option>Select Month</option>
                    {monthNames.map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              ) : selectedType === filterType.year ? (
                <div className="col-md-3">
                  <select
                    className="form-select mt-1"
                    onChange={(e) => handleFY(e.target.value)}
                  >
                    <option value="">Select FY</option>
                    {Array.from({ length: 3 }, (_, index) => {
                      const startYear = currentYear - index;
                      const endYear = startYear + 1;
                      return (
                        <option key={index} value={`${startYear}-${endYear}`}>
                          {startYear}-{endYear}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : selectedType === filterType.dateRange ? (
                <>
                  <div className="col-md-3">
                    <DatePicker
                      className="form-control  mt-1 text_style"
                      dateFormat="yyyy-MM-dd"
                      selected={selectedStartDate}
                      maxDate={getTodayDay()}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      placeholderText="Select Start Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <DatePicker
                      className="form-control  mt-1 text_style"
                      dateFormat="yyyy-MM-dd"
                      selected={selectedEndDate}
                      maxDate={getTodayDay()}
                      onChange={(date) => {
                        setEndDate(date);
                        setOrderInfo([]);
                      }}
                      placeholderText="Select End Date"
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="px-2 mx-1 mt-3">
            {!_.isEmpty(selectedVendor) ? (
              <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Vendor Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Transaction Type</th>
                    <th scope="col">Transaction Value</th>
                    <th scope="col">Total Order Count</th>
                    <th scope="col">Total Order Amount</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Total Payble Commission</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrders?.length > 0 &&
                    allOrders?.map((item) => {
                      return (
                        <tr>
                          <td> {item?.id} </td>
                          <td> {item?.vendor_name} </td>
                          <td> {item?.transaction_type} </td>
                          <td> {item?.amount} </td>

                          <td>
                            <a onClick={() => handleOrderInfo()} href="#">
                              {item?.total_order_count}
                            </a>
                          </td>
                          <td> {item?.total_order_amount} </td>
                          <td> {item?.start_date} </td>
                          <td> {item?.end_date} </td>
                          <td>
                            {item?.transaction_type === "Per Transaction"
                              ? item?.total_order_count * item?.amount
                              : util?.calculatePercetage(
                                  item?.total_order_amount,
                                  item.amount
                                )}
                          </td>
                          <td>
                            {isData(selectedVendor ? selectedVendor?.id : null)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : null}

            {!_.isEmpty(orderInfo) ? (
              <table className="table mt-5 pt-5">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>Order Id</th>
                    <th>Order Amount</th>
                    <th>Order Date</th>
                    <th>Payment Mode</th>
                  </tr>
                </thead>
                <tbody>
                  {orderInfo.map((orderItem, index) => (
                    <>
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <a
                            href="#"
                            onClick={() =>
                              handleOrderDetails(orderItem?.order_id)
                            }
                          >
                            {orderItem?.order_id}
                          </a>
                        </td>
                        <td>{`₹ ${orderItem?.order_amount}`}</td>
                        <td>{dateFormatter(orderItem?.order_date)}</td>
                        <td>{orderItem?.payment_mode}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
        {/* Modal */}
        {showModal.state ? (
          <FUSModal
            title={"Order Items Detail"}
            showModal={showModal.state}
            size={"lg"}
            handleClose={() =>
              setShowModal((prev) => ({ ...prev, state: false }))
            }
            centered
          >
            <div className="row justify-content-between">
              <div className="col-md-auto">
                <h6>Order Id: {orderId} </h6>
              </div>
              <div className="col-md-auto">
                <span className="fw-bold">
                  Order Date: {dateFormatter(order_Date?.order_date)}
                </span>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Category Name</th>
                  <th>Weight</th>
                  <th>Quantity</th>
                  <th>Item Price</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {orderDetail[0]?.items &&
                  orderDetail[0]?.items.map((item, index) => (
                    <tr>
                      <td> {item?.product_name} </td>
                      <td> {item?.category_name} </td>
                      <td> {item?.item_weight}</td>
                      <td> {item?.item_quantity} </td>
                      <td> {item?.item_price} </td>
                      <td> {item?.total_price} </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </FUSModal>
        ) : null}
      </LayoutContainer>
    </>
  );
};

export default VendorInvoicing;

import _ from "lodash";

const calculateTotal = (colData) => {
  let sum = 0;
  colData.map((item) => {
    if (!_.isNil(item)) {
      sum += _.isNumber(parseFloat(item)) ? parseFloat(item) : 0;
    }
  });
  return sum.toFixed(2);
};

const calculatePercetage = (totalValue, percentage) => {
  const result = (totalValue * percentage) / 100;
  return Number(result.toFixed(2));
};

const convertTimeToAMPM = (timeString) => {
  if (timeString === null || timeString === undefined) {
    return "";
  }
  const [hours, minutes] = timeString.split(":").map(Number);
  const time = new Date();
  time.setHours(hours);
  time.setMinutes(minutes);
  const hoursAMPM = time.getHours() % 12 || 12;
  const minutesFormatted = String(time.getMinutes()).padStart(2, "0");
  const AMPM = time.getHours() >= 12 ? "PM" : "AM";
  return `${hoursAMPM}:${minutesFormatted} ${AMPM}`;
};

const dateFormatter = (dates) => {
  const date = new Date(dates);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

function numberToWords(num) {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const scales = ["", "thousand", "lakh", "crore"];

  if (num === 0) return "zero";

  function numToWords(n) {
    if (n < 20) {
      return ones[n];
    } else if (n < 100) {
      return (
        tens[Math.floor(n / 10)] + (n % 10 !== 0 ? " " + ones[n % 10] : "")
      );
    } else if (n < 1000) {
      return (
        ones[Math.floor(n / 100)] +
        " hundred" +
        (n % 100 !== 0 ? " and " + numToWords(n % 100) : "")
      );
    } else {
      let scaleIndex = 0;
      let result = "";
      while (n > 0) {
        const part = n % 100;
        if (part !== 0) {
          result =
            numToWords(part) +
            (scales[scaleIndex] ? " " + scales[scaleIndex] : "") +
            (result ? " " + result : "");
        }
        n = Math.floor(n / 100);
        if (scaleIndex === 0) {
          n = Math.floor(n / 10); // Skip tens place after hundreds
        }
        scaleIndex++;
      }
      return result;
    }
  }

  return numToWords(num);
}

function rupeesInWords(num) {
  const wholePart = Math.floor(num);
  const fractionPart = Math.round((num - wholePart) * 100);
  let words = numberToWords(wholePart) + " rupees";
  if (fractionPart > 0) {
    words += " and " + numberToWords(fractionPart) + " paise";
  }
  return words;
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function dateFormat(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
}

function formatTo12HourTime(dateString) {
  if (!_.isNil(dateString) || !_.isEmpty(dateString)) {
    const date = new Date(dateString);

    // Extract time components
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Hour '0' should be '12'

    // Format time
    const time = `${hours}:${minutes} ${ampm}`;
    return time;
  }
}

const util = {
  calculateTotal,
  calculatePercetage,
  convertTimeToAMPM,
  dateFormatter,
  rupeesInWords,
  monthNames,
  formatDate,
  dateFormat,
  formatTo12HourTime,
};
export default util;

import React, { useState, useEffect } from "react";
import { Chart } from "highcharts";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import DatePicker from "react-datepicker";
import TokenService from "../../../services/token.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import VisitService from "../../../services/VisitService";
import "./VisitAnalytics.css";

const VisitAnalytics = () => {
  const getTodayDay = () => {
    return new Date();
  };
  const token = TokenService.getUser();
  const agency_id = token?.agent?.agent_user_id;
  const [selectedDate, setDate] = useState(getTodayDay());
  const [selectedStartDate, setStartDate] = useState("");
  const [selectedEndDate, setEndDate] = useState("");
  const filterType = {
    date: "date",
    month: "month",
    dateRange: "dateRange",
  };
  const [selectedType, setSelectedType] = useState(filterType.month);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const currentMonth = monthNames[d.getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [chartData, setChartData] = useState([["Date", "Leads Created"]]);
  const [createdParties, setCreatedParties] = useState([
    ["Date", "Parties Created"],
  ]);
  const [visitChartData, setVisitChartData] = useState([
    ["Visit Type", "Number of Visits"],
    ["Leads Visited", 0],
    ["Parties Visited", 0],
  ]);
  const [leadsConvertedToParties, setLeadsConvertedToParties] = useState([
    ["Date", "Converted Leads"],
  ]);
  const [analyticsData, setAnalyticsData] = useState({
    leadsCreated: 0,
    partiesCreated: 0,
    leadsVisited: 0,
    partiesVisited: 0,
    leadsConvertedToParties: 0,
  });

  const barChartOptions = {
    chartArea: { width: "50%" },
    hAxis: {
      title: "Number of Leads",
      minValue: 0,
    },
    vAxis: {
      title: "Time Period",
    },
    isStacked: true,
    colors: ["#7966e4"],
  };

  const createdPartiesOptions = {
    chartArea: { width: "50%" },
    hAxis: {
      title: "Number of Parties",
      minValue: 0,
    },
    vAxis: {
      title: "Time Period",
    },
    isStacked: true,
    colors: ["#7966e4"],
  };

  const leadsConvertedToPartiesOptions = {
    chartArea: { width: "50%" },
    hAxis: {
      title: "Leads Converted to Parties",
      minValue: 0,
    },
    vAxis: {
      title: "Time Period",
    },
    isStacked: true,
    colors: ["#7966e4"],
  };

  useEffect(() => {
    const getStartEndDateForMonth = (month) => {
      const monthIndex = monthNames.indexOf(month);
      const firstDateOfMonth = new Date(d.getFullYear(), monthIndex, 1);
      const lastDateOfMonth = new Date(d.getFullYear(), monthIndex + 1, 0);
      return { firstDateOfMonth, lastDateOfMonth };
    };

    if (selectedType === filterType.date) {
      getLeadsByAgencyId(agency_id, selectedDate, selectedDate, "DATE");
      getVisitData(agency_id, selectedDate, selectedDate);
      getPartiesByAgencyId(agency_id, selectedDate, selectedDate, "DATE");
      getAllLeadsConvertedToParties(
        agency_id,
        selectedDate,
        selectedDate,
        "DATE"
      );
    } else if (
      selectedType === filterType.dateRange &&
      selectedStartDate &&
      selectedEndDate
    ) {
      getLeadsByAgencyId(
        agency_id,
        selectedStartDate,
        selectedEndDate,
        "DATE RANGE"
      );
      getVisitData(agency_id, selectedStartDate, selectedEndDate);
      getPartiesByAgencyId(
        agency_id,
        selectedStartDate,
        selectedEndDate,
        "DATE RANGE"
      );
      getAllLeadsConvertedToParties(
        agency_id,
        selectedStartDate,
        selectedEndDate,
        "DATE RANGE"
      );
    } else if (selectedType === filterType.month) {
      const { firstDateOfMonth, lastDateOfMonth } =
        getStartEndDateForMonth(selectedMonth);
      getLeadsByAgencyId(agency_id, firstDateOfMonth, lastDateOfMonth, "MONTH");
      getVisitData(agency_id, firstDateOfMonth, lastDateOfMonth);
      getPartiesByAgencyId(
        agency_id,
        firstDateOfMonth,
        lastDateOfMonth,
        "MONTH"
      );
      getAllLeadsConvertedToParties(
        agency_id,
        firstDateOfMonth,
        lastDateOfMonth,
        "MONTH"
      );
    }
  }, [
    selectedDate,
    selectedStartDate,
    selectedEndDate,
    selectedMonth,
    selectedType,
    agency_id,
  ]);

  const getLeadsByAgencyId = async (agency_id, start_date, end_date, flag) => {
    try {
      const formatDate = (date) => {
        return new Date(date).toLocaleDateString("en-CA");
      };
      const params = {
        agency_id,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
        flag,
      };
      const result = await VisitService?.getLeadsByAgencyId(params);
      if (result?.data?.errorCode === 0) {
        const data = result?.data?.data;
        const formattedData = [
          ["Date", "Leads Created "],
          ...data.map((row) => {
            const date = row.created_date;
            const recordCount = parseInt(row.total_records, 10);
            return [date, recordCount];
          }),
        ];
        setChartData(formattedData);
      } else {
        const formattedData = [
          ["Date", "Leads Created "],
          [null, 0],
        ];
        setChartData(formattedData);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getPartiesByAgencyId = async (
    agency_id,
    start_date,
    end_date,
    flag
  ) => {
    try {
      const formatDate = (date) => {
        return new Date(date).toLocaleDateString("en-CA");
      };
      const params = {
        agency_id,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
        flag,
      };
      const result = await VisitService?.getPartiesByAgencyId(params);
      if (result?.data?.errorCode === 0) {
        const data = result?.data?.data;
        const formattedData = [
          ["Date", "Parties Created "],
          ...data.map((row) => {
            const date = row.created_date;
            const recordCount = parseInt(row.total_records, 10);
            return [date, recordCount];
          }),
        ];
        setCreatedParties(formattedData);
      } else {
        const formattedData = [
          ["Date", "Parties Created "],
          [null, 0],
        ];
        setCreatedParties(formattedData);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getVisitData = async (agency_id, start_date, end_date) => {
    try {
      const formatDate = (date) => {
        return new Date(date).toLocaleDateString("en-CA");
      };
      const params = {
        agency_id,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
      };
      const result = await VisitService.getVisitsOnLeadsAndParties(params);
      if (result?.data?.errorCode === 0) {
        const visits_on_leads =
          result.data.data.visits_on_leads?.[0]?.visits_on_leads || 0;
        const visits_on_parties =
          result.data.data.visits_on_parties?.[0]?.visits_on_parties || 0;
        setVisitChartData([
          ["Visit Type", "Number of Visits"],
          ["Leads Visited", parseInt(visits_on_leads, 10)],
          ["Parties Visited", parseInt(visits_on_parties, 10)],
        ]);
      } else {
        setVisitChartData([
          ["Visit Type", "Number of Visits"],
          ["Leads Visited", 0],
          ["Parties Visited", 0],
        ]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getAllLeadsConvertedToParties = async (
    agency_id,
    start_date,
    end_date,
    flag
  ) => {
    try {
      const formatDate = (date) => {
        return new Date(date).toLocaleDateString("en-CA");
      };
      const params = {
        agency_id,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
        flag,
      };
      const result = await VisitService?.getAllLeadsConvertedToParties(params);
      if (result?.data?.errorCode === 0) {
        const data = result?.data?.data;
        const formattedData = [
          ["Date", "Converted Leads "],
          ...data.map((row) => {
            const date = row.created_date;
            const recordCount = parseInt(row.total_records, 10);
            return [date, recordCount];
          }),
        ];
        setLeadsConvertedToParties(formattedData);
      } else {
        const formattedData = [
          ["Date", "Converted Leads "],
          [null, 0],
        ];
        setLeadsConvertedToParties(formattedData);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getAnalyticsData = async (agency_id) => {
    try {
      const result = await VisitService.getLeadAndPartyMetrics({ agency_id });
      if (result?.data?.errorCode === 0) {
        const {
          leads_created,
          parties_created,
          leads_visited,
          parties_visited,
          leads_converted_to_parties,
        } = result.data.data[0];
        setAnalyticsData({
          leadsCreated: parseInt(leads_created, 10),
          partiesCreated: parseInt(parties_created, 10),
          leadsVisited: parseInt(leads_visited, 10),
          partiesVisited: parseInt(parties_visited, 10),
          leadsConvertedToParties: parseInt(leads_converted_to_parties, 10),
        });
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    if (agency_id) {
      getAnalyticsData(agency_id);
    }
  }, [agency_id]);

  return (
    <LayoutContainer
      title1={"Visit Management"}
      title2={"Visit Management"}
      title3={"Analytics"}
    >
      <div>
        <div className="row">
          <div className="col-md-2">
            <select
              className="form-select mt-1"
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e.target.value);
                setSelectedMonth(currentMonth);
                setDate(getTodayDay());
                setStartDate("");
                setEndDate("");
              }}
            >
              <option value="">Select Option</option>
              <option value={filterType.date}>Date</option>
              <option value={filterType.month}>Month</option>
              <option value={filterType.dateRange}>Date Range</option>
            </select>
          </div>
          {selectedType === filterType.date ? (
            <div className="col-md-2">
              <DatePicker
                className="form-control mt-1"
                dateFormat="yyyy-MM-dd"
                selected={selectedDate}
                maxDate={getTodayDay()}
                onChange={(date) => {
                  setDate(date);
                }}
                placeholderText="Select Date"
              />
            </div>
          ) : selectedType === filterType.month ? (
            <div className="col-md-2">
              <select
                className="form-select mt-1"
                value={selectedMonth}
                onChange={(e) => {
                  setSelectedMonth(e.target.value);
                }}
              >
                <option>Select Month</option>
                {monthNames.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          ) : selectedType === filterType.dateRange ? (
            <>
              <div className="col-md-2">
                <DatePicker
                  className="form-control mt-1"
                  dateFormat="yyyy-MM-dd"
                  selected={selectedStartDate}
                  maxDate={getTodayDay()}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  placeholderText="Select Start Date"
                />
              </div>
              <div className="col-md-2">
                <DatePicker
                  className="form-control mt-1"
                  dateFormat="yyyy-MM-dd"
                  selected={selectedEndDate}
                  maxDate={getTodayDay()}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  placeholderText="Select End Date"
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="grid-container">
          <div className="chart-container">
            <h4>Leads Created</h4>
            <Chart
              chartType="BarChart"
              width="100%"
              height="100%"
              data={chartData}
              options={barChartOptions}
            />
            <div className="text-style">
              <h5>Total Leads Created : {analyticsData.leadsCreated}</h5>
            </div>
          </div>

          <div className="chart-container">
            <h4>Parties Created</h4>
            <Chart
              chartType="BarChart"
              width="100%"
              height="100%"
              data={createdParties}
              options={createdPartiesOptions}
            />
            <div className="text-style">
              <h5>Total Parties Created : {analyticsData.partiesCreated}</h5>
            </div>
          </div>

          <div className="chart-container">
            <h4>Agent/Collector Visits</h4>
            <Chart
              chartType="PieChart"
              width="100%"
              height="100%"
              data={visitChartData}
              options={{
                is3D: true,
                colors: ["#7966e4", "#c4cdd5"],
                slices: {
                  0: { offset: 0.1 },
                  1: { offset: 0.1 },
                },
              }}
            />
            <div className="text-style">
              <h5>Total Visits on Leads: {analyticsData.leadsVisited}</h5>
              <h5>Total Visits on Parties: {analyticsData.partiesVisited}</h5>
            </div>
          </div>

          <div className="chart-container">
            <h4>Leads Converted To Parties</h4>
            <Chart
              chartType="BarChart"
              width="100%"
              height="100%"
              data={leadsConvertedToParties}
              options={leadsConvertedToPartiesOptions}
            />
            <div className="text-style">
              <h5>
                Total Leads Converted To Parties :{" "}
                {analyticsData.leadsConvertedToParties}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default VisitAnalytics;

import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import VendorService from "../../services/vendor.services";
import DataTable from "../DataTable/DataTable";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { Col, Row } from "react-bootstrap";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";
import MstService from "../../services/mst.service";

const TravelExpenses = () => {
  const [travelExpensesData, setTravelExpensesData] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorLocation, setVendorLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const FLAG = {
    INSERT: "INSERT",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
  };
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const findFormErrors = () => {
    const { vendorName,locationName, travelExpenses } = form;
    const newErrors = {};
    // agency name errors
    if (!vendorName || vendorName === "" || vendorName=== "-1") {
      newErrors.vendorName = "Vendor Name cannot be blank!";
    } 
    else if (!locationName || locationName === "" || locationName=== "-1") {
      newErrors.locationName = "Vendor Location cannot be blank!";
    } 
    // else if (vendorLocationId.length > 100) {
    //   newErrors.vendorLocationId = "Vendor Location Id is too long!";
    // }
    // description name errors
    if (!travelExpenses || travelExpenses === "")
      newErrors.travelExpenses = "Travel Expenses cannot be blank!";

    return newErrors;
  };

  const vendorWiseTravelExpensesConfiguration = async (postForm) => {
    
    await VendorService?.vendorWiseTravelExpensesConfiguration(postForm)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setIsLoading((prev) => !prev);
          toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        } else {
          toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => requestAndErrorHandler.errorHandler(error));
  };

  const getVendorsList = async () => {
    await MstService.getAllVendorsAndAgency()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorList(res?.data?.data);
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getLocationData = async (vendor_id) => {
    try {
      const result = await VendorService.getVendorLocation({
        vendor_user_id: vendor_id,
      });

      if (result?.data?.errorCode === 0) {
        setVendorLocations(result.data.data);
      } else {
        setVendorLocations([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
     
      setErrors(newErrors);
      
    } else {
      vendorWiseTravelExpensesConfiguration({
        flag:
          showModal.type === "CREATE_TRAVEL_EXPENSES" ? FLAG.INSERT : FLAG.UPDATE,
        ...form,
        locationCheck:true
      });

      setShowModal((prev) => ({ ...prev, state: false }));
    }
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  
  const getTravelExpensesConfig = async () => {
    await VendorService?.getTravelExpensesConfig()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          
          setTravelExpensesData(response?.data?.data);
        } else {
            setTravelExpensesData([]);
        }
      })
      .catch((error) => requestAndErrorHandler.errorHandler(error));
  };

  useEffect(() => {
    getVendorsList();
    getTravelExpensesConfig();
  }, [isLoading]);

  const GetAction = (cell, row) => {
    return (
      <div className="d-flex gap-2">
        <FUSButton
          className={"action_button_style"}
          iconSrc="md"
          iconName="MdOutlineModeEdit"
          iconSize={16}
          buttonType="lightgray"
          onClick={() => {
            setShowModal((prev) => ({
              ...prev,
              state: true,
              type: "EDIT_TRAVEL_EXPENSES",
            }));
            setForm({
              ...row,
              travelExpenses: row.travel_expenses,
              // vendorLocationId: row.vendor_location_id,
              vendorName:row.vendor_name,
              locationName: row.location_name
            });
          }}
        />

        <FUSButton
          className={"action_button_style"}
          iconSrc="md"
          iconName="MdDeleteForever"
          iconSize={15}
          buttonType="lightdanger"
          onClick={() => {
            var confirm = window.confirm(
              "Do you want to delete the Travel Expense for this location "
            );
            if (confirm) {
              vendorWiseTravelExpensesConfiguration({
                flag: FLAG.DELETE,
                ...form,
                rec_id: row.rec_id,
              });
            }
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "vendor_name",
      text: "Vendor Name",
      sort: true,
    },
    {
      dataField: "location_name",
      text: "Vendor Location ",
      sort: true,
    },
    {
      dataField: "travel_expense",
      text: "Travel Expenses",
    },
    {
      text: "Action",
      dataField: "",
      formatter: GetAction,
    },
  ];
  return (
    <>
      <LayoutContainer
        title2={"Configuration"}
        title3={"Travel Expenses"}
        right={
          <>
            <div>
              <FUSButton
                iconSrc={"fa6"}
                iconName={"FaPlus"}
                iconSize={14}
                labelText={"Add Travel Expenses"}
                buttonType="primary"
                onClick={() => {
                  setShowModal((prev) => ({
                    ...prev,
                    state: true,
                    type: "CREATE_TRAVEL_EXPENSES",
                  }));
                  setForm({});
                }}
              ></FUSButton>
            </div>
          </>
        }
      >
        {travelExpensesData.length > 0 ? (
          <div className="px-2">
            <DataTable
              id={"travelExpensesData"}
              data={travelExpensesData}
              columns={columns}
              sizePerPage={5}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="pb-5 ">
                  <FUSEmptyList title={"No Vendor Travel Expenses Found"} />
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutContainer>
      {showModal.state && showModal.type === "CREATE_TRAVEL_EXPENSES" && (
        <FUSModal
          title={
            showModal.type === "CREATE_TRAVEL_EXPENSES"
              ? "Create Travel Expenses"
              : "Edit Travel Expenses"
          }
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>{
            setShowModal((prev) => ({ ...prev, state: false }))
            setForm({});
            setVendorLocations([]);
            setErrors({});
          }
          }
          centered
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <Row>
                  <div className="col-12 px-0 mb-3">
                    <span className="text-secondary mb-2">Select Vendor</span>
                    <div>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setField("vendorName", e.target.value);
                          getLocationData(e.target.value);
                        }}
                      >
                        <option value={-1}>Select Vendor</option>
                        {vendorList?.length > 0 &&
                          vendorList?.map((item, index) => (
                            <option value={item?.user_id} key={index}>
                              {item?.user_name}
                            </option>
                          ))}
                      </select>
                      {errors?.vendorName && (
                      <p className="text-danger">{errors?.vendorName}</p>
                    )}
                    </div>
                  </div>
                  <div className="col-12 px-0 mb-3">
                    <span className="text-secondary mb-2">
                      Select Vendor Location
                    </span>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setField("locationName", e.target.value);
                        setErrors({});
                      }}
                    >
                      <option value={-1}>Select Location</option>
                      {vendorLocation &&
                        vendorLocation.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.location_name}
                          </option>
                        ))}
                    </select>
                    {errors?.locationName && (
                      <p className="text-danger">{errors?.locationName}</p>
                    )}
                  </div>

                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Travel Expenses</span>
                      <input
                        type="number"
                        className="form-control text-secondary mb-2"
                        placeholder="Enter Travel Expense"
                        onChange={(e) =>
                          setField("travelExpenses", e.target.value)
                        }
                        value={form?.travelExpenses}
                        isInvalid={!!errors.travelExpenses}
                        // required
                      />
                      {errors.travelExpenses && (
                        <p className="text-danger">{errors.travelExpenses}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={6} md={12} className="px-0">
                    <div>
                      <FUSButton
                        className={"form-control py-2"}
                        buttonType="primary"
                        labelText={
                          showModal.type === "CREATE_TRAVEL_EXPENSES"
                            ? "Add"
                            : "Edit"
                        }
                        type={"submit"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
      {showModal.state && showModal.type === "EDIT_TRAVEL_EXPENSES" && (
        <FUSModal
          title={"Edit Travel Expenses"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>{
            setShowModal((prev) => ({ ...prev, state: false }))
            setForm({});
            setVendorLocations([]);
            setErrors({});
          }}
          centered
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <Row>
                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Travel Expenses</span>
                      <input
                        type="number"
                        className="form-control text-secondary mb-2"
                        placeholder="Enter Travel Expenses"
                        onChange={(e) =>
                          setField("travelExpenses", e.target.value)
                        }
                        value={form?.travelExpenses}
                        isInvalid={!!errors.travelExpenses}
                        required
                      />
                      {errors.travelExpenses && (
                        <p className="text-warning">{errors.travelExpenses}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={6} md={12} className="px-0">
                    <div>
                      <FUSButton
                        className={"form-control py-2"}
                        buttonType="primary"
                        labelText={
                          showModal.type === "CREATE_TRAVEL_EXPENSES"
                            ? "Add"
                            : "Update"
                        }
                        type={"submit"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default TravelExpenses;
